//colors
$white: #ffffff;
$body-bg: #f7f9f9;
$gray:#F7FBED;
$gray-main: #808080;
$gray-body:#838383;
$gray-D5:#D5D5D5;
$war-bg:#F1DEDE;
$war-text:#AB4441;
$success-bg:#DFEFD8;
$success-text:#44763C;
$d-blue:#015488;
$header-blue: #007FCE;
$light3: #f3f3f3;
$lightdark3: #333333;
$light: #eeeeee;


//font-sizes
$font-32:32px;
$font-30:30px;
$font-28:28px;
$font-24:24px;
$font-25:25px;
$font-22:22px;
$font-20:20px;
$font-19:19px;
$font-18:18px;
$font-16:16px;
$font-15:15px;
$font-14:14px;
$font-13:13px;
$font-12:12px;
$font-11:11px;
$font-10:10px;
$font-9:9px;

//device sizes
$lg:992px;
//$sm:767px;
$max-lg:1200px;
$max-lg1:1199px;
$sm:575px;
$min-sm:576px;
$md:767px;
$min-md:768px;
$max-md:991px;

//images links
$login-bg: '/assets/images/login-bg-1.jpg';


//mixins

@mixin background($color) {
	background: $color !important;
}

@mixin text-display($size, $color, $weight) {
	font-size: $size;
	color: $color;
	font-weight: $weight;
}