/* You can add global styles to this file, and also import other style files */

@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './assets/material-design/scss/materialdesignicons.scss';
@import './assets/styles/common.scss';
html, body { height: 100%; }
 body { margin: 0;  font-family: "Century Gothic" !important; }

 h1, h2, h3, h4, h5, h6, p, span{
    font-family: "Century Gothic" !important;
 }


@font-face {
    font-family: "Century Gothic";
    src: url("./assets/font/Century-Gothic.ttf");
}

@font-face {
    font-family: "Century Gothic Bold";
    src: url("./assets/font/Century-Gothic-Bold.TTF");
}


.cke_toolgroup {
    background-image: linear-gradient(rgb(255 255 255), rgb(255 255 255)) !important;
    border-color: rgb(0 0 0) !important;
}

.cke_combo_button {
    background-image: linear-gradient(rgb(255 255 255), rgb(255 255 255)) !important;
    border-color: rgb(0 0 0) !important;
}

@media (min-width: 1200px){
    .container{
        max-width: 1200px;
    }
}

@media (max-width: 576px){
.container {

    padding-left: 15px !important;
    padding-right: 15px !important;
}
}

.printScreen {
    &::after {
        content: '';
        position: fixed;
        top: 0;
        height: 100vh;
        width: 100vw;
        background-color: black;
    }
}


