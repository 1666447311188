@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("/assets/material-design/fonts/materialdesignicons-webfont.eot?v=2.0.46");
  src: url("/assets/material-design/fonts/materialdesignicons-webfont.eot?#iefix&v=2.0.46") format("embedded-opentype"), url("/assets/material-design/fonts/materialdesignicons-webfont.woff2?v=2.0.46") format("woff2"), url("/assets/material-design/fonts/materialdesignicons-webfont.woff?v=2.0.46") format("woff"), url("/assets/material-design/fonts/materialdesignicons-webfont.ttf?v=2.0.46") format("truetype"), url("/assets/material-design/fonts/materialdesignicons-webfont.svg?v=2.0.46#materialdesigniconsregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdi-access-point:before {
  content: "\F002";
}

.mdi-access-point-network:before {
  content: "\F003";
}

.mdi-account:before {
  content: "\F004";
}

.mdi-account-alert:before {
  content: "\F005";
}

.mdi-account-box:before {
  content: "\F006";
}

.mdi-account-box-outline:before {
  content: "\F007";
}

.mdi-account-card-details:before {
  content: "\F5D2";
}

.mdi-account-check:before {
  content: "\F008";
}

.mdi-account-circle:before {
  content: "\F009";
}

.mdi-account-convert:before {
  content: "\F00A";
}

.mdi-account-edit:before {
  content: "\F6BB";
}

.mdi-account-key:before {
  content: "\F00B";
}

.mdi-account-location:before {
  content: "\F00C";
}

.mdi-account-minus:before {
  content: "\F00D";
}

.mdi-account-multiple:before {
  content: "\F00E";
}

.mdi-account-multiple-minus:before {
  content: "\F5D3";
}

.mdi-account-multiple-outline:before {
  content: "\F00F";
}

.mdi-account-multiple-plus:before {
  content: "\F010";
}

.mdi-account-network:before {
  content: "\F011";
}

.mdi-account-off:before {
  content: "\F012";
}

.mdi-account-outline:before {
  content: "\F013";
}

.mdi-account-plus:before {
  content: "\F014";
}

.mdi-account-remove:before {
  content: "\F015";
}

.mdi-account-search:before {
  content: "\F016";
}

.mdi-account-settings:before {
  content: "\F630";
}

.mdi-account-settings-variant:before {
  content: "\F631";
}

.mdi-account-star:before {
  content: "\F017";
}

.mdi-account-switch:before {
  content: "\F019";
}

.mdi-adjust:before {
  content: "\F01A";
}

.mdi-air-conditioner:before {
  content: "\F01B";
}

.mdi-airballoon:before {
  content: "\F01C";
}

.mdi-airplane:before {
  content: "\F01D";
}

.mdi-airplane-landing:before {
  content: "\F5D4";
}

.mdi-airplane-off:before {
  content: "\F01E";
}

.mdi-airplane-takeoff:before {
  content: "\F5D5";
}

.mdi-airplay:before {
  content: "\F01F";
}

.mdi-alarm:before {
  content: "\F020";
}

.mdi-alarm-bell:before {
  content: "\F78D";
}

.mdi-alarm-check:before {
  content: "\F021";
}

.mdi-alarm-light:before {
  content: "\F78E";
}

.mdi-alarm-multiple:before {
  content: "\F022";
}

.mdi-alarm-off:before {
  content: "\F023";
}

.mdi-alarm-plus:before {
  content: "\F024";
}

.mdi-alarm-snooze:before {
  content: "\F68D";
}

.mdi-album:before {
  content: "\F025";
}

.mdi-alert:before {
  content: "\F026";
}

.mdi-alert-box:before {
  content: "\F027";
}

.mdi-alert-circle:before {
  content: "\F028";
}

.mdi-alert-circle-outline:before {
  content: "\F5D6";
}

.mdi-alert-decagram:before {
  content: "\F6BC";
}

.mdi-alert-octagon:before {
  content: "\F029";
}

.mdi-alert-octagram:before {
  content: "\F766";
}

.mdi-alert-outline:before {
  content: "\F02A";
}

.mdi-all-inclusive:before {
  content: "\F6BD";
}

.mdi-alpha:before {
  content: "\F02B";
}

.mdi-alphabetical:before {
  content: "\F02C";
}

.mdi-altimeter:before {
  content: "\F5D7";
}

.mdi-amazon:before {
  content: "\F02D";
}

.mdi-amazon-clouddrive:before {
  content: "\F02E";
}

.mdi-ambulance:before {
  content: "\F02F";
}

.mdi-amplifier:before {
  content: "\F030";
}

.mdi-anchor:before {
  content: "\F031";
}

.mdi-android:before {
  content: "\F032";
}

.mdi-android-debug-bridge:before {
  content: "\F033";
}

.mdi-android-head:before {
  content: "\F78F";
}

.mdi-android-studio:before {
  content: "\F034";
}

.mdi-angular:before {
  content: "\F6B1";
}

.mdi-angularjs:before {
  content: "\F6BE";
}

.mdi-animation:before {
  content: "\F5D8";
}

.mdi-apple:before {
  content: "\F035";
}

.mdi-apple-finder:before {
  content: "\F036";
}

.mdi-apple-ios:before {
  content: "\F037";
}

.mdi-apple-keyboard-caps:before {
  content: "\F632";
}

.mdi-apple-keyboard-command:before {
  content: "\F633";
}

.mdi-apple-keyboard-control:before {
  content: "\F634";
}

.mdi-apple-keyboard-option:before {
  content: "\F635";
}

.mdi-apple-keyboard-shift:before {
  content: "\F636";
}

.mdi-apple-mobileme:before {
  content: "\F038";
}

.mdi-apple-safari:before {
  content: "\F039";
}

.mdi-application:before {
  content: "\F614";
}

.mdi-approval:before {
  content: "\F790";
}

.mdi-apps:before {
  content: "\F03B";
}

.mdi-archive:before {
  content: "\F03C";
}

.mdi-arrange-bring-forward:before {
  content: "\F03D";
}

.mdi-arrange-bring-to-front:before {
  content: "\F03E";
}

.mdi-arrange-send-backward:before {
  content: "\F03F";
}

.mdi-arrange-send-to-back:before {
  content: "\F040";
}

.mdi-arrow-all:before {
  content: "\F041";
}

.mdi-arrow-bottom-left:before {
  content: "\F042";
}

.mdi-arrow-bottom-right:before {
  content: "\F043";
}

.mdi-arrow-collapse:before {
  content: "\F615";
}

.mdi-arrow-collapse-all:before {
  content: "\F044";
}

.mdi-arrow-collapse-down:before {
  content: "\F791";
}

.mdi-arrow-collapse-left:before {
  content: "\F792";
}

.mdi-arrow-collapse-right:before {
  content: "\F793";
}

.mdi-arrow-collapse-up:before {
  content: "\F794";
}

.mdi-arrow-down:before {
  content: "\F045";
}

.mdi-arrow-down-bold:before {
  content: "\F72D";
}

.mdi-arrow-down-bold-box:before {
  content: "\F72E";
}

.mdi-arrow-down-bold-box-outline:before {
  content: "\F72F";
}

.mdi-arrow-down-bold-circle:before {
  content: "\F047";
}

.mdi-arrow-down-bold-circle-outline:before {
  content: "\F048";
}

.mdi-arrow-down-bold-hexagon-outline:before {
  content: "\F049";
}

.mdi-arrow-down-box:before {
  content: "\F6BF";
}

.mdi-arrow-down-drop-circle:before {
  content: "\F04A";
}

.mdi-arrow-down-drop-circle-outline:before {
  content: "\F04B";
}

.mdi-arrow-down-thick:before {
  content: "\F046";
}

.mdi-arrow-expand:before {
  content: "\F616";
}

.mdi-arrow-expand-all:before {
  content: "\F04C";
}

.mdi-arrow-expand-down:before {
  content: "\F795";
}

.mdi-arrow-expand-left:before {
  content: "\F796";
}

.mdi-arrow-expand-right:before {
  content: "\F797";
}

.mdi-arrow-expand-up:before {
  content: "\F798";
}

.mdi-arrow-left:before {
  content: "\F04D";
}

.mdi-arrow-left-bold:before {
  content: "\F730";
}

.mdi-arrow-left-bold-box:before {
  content: "\F731";
}

.mdi-arrow-left-bold-box-outline:before {
  content: "\F732";
}

.mdi-arrow-left-bold-circle:before {
  content: "\F04F";
}

.mdi-arrow-left-bold-circle-outline:before {
  content: "\F050";
}

.mdi-arrow-left-bold-hexagon-outline:before {
  content: "\F051";
}

.mdi-arrow-left-box:before {
  content: "\F6C0";
}

.mdi-arrow-left-drop-circle:before {
  content: "\F052";
}

.mdi-arrow-left-drop-circle-outline:before {
  content: "\F053";
}

.mdi-arrow-left-thick:before {
  content: "\F04E";
}

.mdi-arrow-right:before {
  content: "\F054";
}

.mdi-arrow-right-bold:before {
  content: "\F733";
}

.mdi-arrow-right-bold-box:before {
  content: "\F734";
}

.mdi-arrow-right-bold-box-outline:before {
  content: "\F735";
}

.mdi-arrow-right-bold-circle:before {
  content: "\F056";
}

.mdi-arrow-right-bold-circle-outline:before {
  content: "\F057";
}

.mdi-arrow-right-bold-hexagon-outline:before {
  content: "\F058";
}

.mdi-arrow-right-box:before {
  content: "\F6C1";
}

.mdi-arrow-right-drop-circle:before {
  content: "\F059";
}

.mdi-arrow-right-drop-circle-outline:before {
  content: "\F05A";
}

.mdi-arrow-right-thick:before {
  content: "\F055";
}

.mdi-arrow-top-left:before {
  content: "\F05B";
}

.mdi-arrow-top-right:before {
  content: "\F05C";
}

.mdi-arrow-up:before {
  content: "\F05D";
}

.mdi-arrow-up-bold:before {
  content: "\F736";
}

.mdi-arrow-up-bold-box:before {
  content: "\F737";
}

.mdi-arrow-up-bold-box-outline:before {
  content: "\F738";
}

.mdi-arrow-up-bold-circle:before {
  content: "\F05F";
}

.mdi-arrow-up-bold-circle-outline:before {
  content: "\F060";
}

.mdi-arrow-up-bold-hexagon-outline:before {
  content: "\F061";
}

.mdi-arrow-up-box:before {
  content: "\F6C2";
}

.mdi-arrow-up-drop-circle:before {
  content: "\F062";
}

.mdi-arrow-up-drop-circle-outline:before {
  content: "\F063";
}

.mdi-arrow-up-thick:before {
  content: "\F05E";
}

.mdi-assistant:before {
  content: "\F064";
}

.mdi-asterisk:before {
  content: "\F6C3";
}

.mdi-at:before {
  content: "\F065";
}

.mdi-atom:before {
  content: "\F767";
}

.mdi-attachment:before {
  content: "\F066";
}

.mdi-audiobook:before {
  content: "\F067";
}

.mdi-auto-fix:before {
  content: "\F068";
}

.mdi-auto-upload:before {
  content: "\F069";
}

.mdi-autorenew:before {
  content: "\F06A";
}

.mdi-av-timer:before {
  content: "\F06B";
}

.mdi-baby:before {
  content: "\F06C";
}

.mdi-baby-buggy:before {
  content: "\F68E";
}

.mdi-backburger:before {
  content: "\F06D";
}

.mdi-backspace:before {
  content: "\F06E";
}

.mdi-backup-restore:before {
  content: "\F06F";
}

.mdi-bandcamp:before {
  content: "\F674";
}

.mdi-bank:before {
  content: "\F070";
}

.mdi-barcode:before {
  content: "\F071";
}

.mdi-barcode-scan:before {
  content: "\F072";
}

.mdi-barley:before {
  content: "\F073";
}

.mdi-barrel:before {
  content: "\F074";
}

.mdi-basecamp:before {
  content: "\F075";
}

.mdi-basket:before {
  content: "\F076";
}

.mdi-basket-fill:before {
  content: "\F077";
}

.mdi-basket-unfill:before {
  content: "\F078";
}

.mdi-battery:before {
  content: "\F079";
}

.mdi-battery-10:before {
  content: "\F07A";
}

.mdi-battery-20:before {
  content: "\F07B";
}

.mdi-battery-30:before {
  content: "\F07C";
}

.mdi-battery-40:before {
  content: "\F07D";
}

.mdi-battery-50:before {
  content: "\F07E";
}

.mdi-battery-60:before {
  content: "\F07F";
}

.mdi-battery-70:before {
  content: "\F080";
}

.mdi-battery-80:before {
  content: "\F081";
}

.mdi-battery-90:before {
  content: "\F082";
}

.mdi-battery-alert:before {
  content: "\F083";
}

.mdi-battery-charging:before {
  content: "\F084";
}

.mdi-battery-charging-100:before {
  content: "\F085";
}

.mdi-battery-charging-20:before {
  content: "\F086";
}

.mdi-battery-charging-30:before {
  content: "\F087";
}

.mdi-battery-charging-40:before {
  content: "\F088";
}

.mdi-battery-charging-60:before {
  content: "\F089";
}

.mdi-battery-charging-80:before {
  content: "\F08A";
}

.mdi-battery-charging-90:before {
  content: "\F08B";
}

.mdi-battery-minus:before {
  content: "\F08C";
}

.mdi-battery-negative:before {
  content: "\F08D";
}

.mdi-battery-outline:before {
  content: "\F08E";
}

.mdi-battery-plus:before {
  content: "\F08F";
}

.mdi-battery-positive:before {
  content: "\F090";
}

.mdi-battery-unknown:before {
  content: "\F091";
}

.mdi-beach:before {
  content: "\F092";
}

.mdi-beaker:before {
  content: "\F68F";
}

.mdi-beats:before {
  content: "\F097";
}

.mdi-beer:before {
  content: "\F098";
}

.mdi-behance:before {
  content: "\F099";
}

.mdi-bell:before {
  content: "\F09A";
}

.mdi-bell-off:before {
  content: "\F09B";
}

.mdi-bell-outline:before {
  content: "\F09C";
}

.mdi-bell-plus:before {
  content: "\F09D";
}

.mdi-bell-ring:before {
  content: "\F09E";
}

.mdi-bell-ring-outline:before {
  content: "\F09F";
}

.mdi-bell-sleep:before {
  content: "\F0A0";
}

.mdi-beta:before {
  content: "\F0A1";
}

.mdi-bible:before {
  content: "\F0A2";
}

.mdi-bike:before {
  content: "\F0A3";
}

.mdi-bing:before {
  content: "\F0A4";
}

.mdi-binoculars:before {
  content: "\F0A5";
}

.mdi-bio:before {
  content: "\F0A6";
}

.mdi-biohazard:before {
  content: "\F0A7";
}

.mdi-bitbucket:before {
  content: "\F0A8";
}

.mdi-black-mesa:before {
  content: "\F0A9";
}

.mdi-blackberry:before {
  content: "\F0AA";
}

.mdi-blender:before {
  content: "\F0AB";
}

.mdi-blinds:before {
  content: "\F0AC";
}

.mdi-block-helper:before {
  content: "\F0AD";
}

.mdi-blogger:before {
  content: "\F0AE";
}

.mdi-bluetooth:before {
  content: "\F0AF";
}

.mdi-bluetooth-audio:before {
  content: "\F0B0";
}

.mdi-bluetooth-connect:before {
  content: "\F0B1";
}

.mdi-bluetooth-off:before {
  content: "\F0B2";
}

.mdi-bluetooth-settings:before {
  content: "\F0B3";
}

.mdi-bluetooth-transfer:before {
  content: "\F0B4";
}

.mdi-blur:before {
  content: "\F0B5";
}

.mdi-blur-linear:before {
  content: "\F0B6";
}

.mdi-blur-off:before {
  content: "\F0B7";
}

.mdi-blur-radial:before {
  content: "\F0B8";
}

.mdi-bomb:before {
  content: "\F690";
}

.mdi-bomb-off:before {
  content: "\F6C4";
}

.mdi-bone:before {
  content: "\F0B9";
}

.mdi-book:before {
  content: "\F0BA";
}

.mdi-book-minus:before {
  content: "\F5D9";
}

.mdi-book-multiple:before {
  content: "\F0BB";
}

.mdi-book-multiple-variant:before {
  content: "\F0BC";
}

.mdi-book-open:before {
  content: "\F0BD";
}

.mdi-book-open-page-variant:before {
  content: "\F5DA";
}

.mdi-book-open-variant:before {
  content: "\F0BE";
}

.mdi-book-plus:before {
  content: "\F5DB";
}

.mdi-book-secure:before {
  content: "\F799";
}

.mdi-book-unsecure:before {
  content: "\F79A";
}

.mdi-book-variant:before {
  content: "\F0BF";
}

.mdi-bookmark:before {
  content: "\F0C0";
}

.mdi-bookmark-check:before {
  content: "\F0C1";
}

.mdi-bookmark-music:before {
  content: "\F0C2";
}

.mdi-bookmark-outline:before {
  content: "\F0C3";
}

.mdi-bookmark-plus:before {
  content: "\F0C5";
}

.mdi-bookmark-plus-outline:before {
  content: "\F0C4";
}

.mdi-bookmark-remove:before {
  content: "\F0C6";
}

.mdi-boombox:before {
  content: "\F5DC";
}

.mdi-bootstrap:before {
  content: "\F6C5";
}

.mdi-border-all:before {
  content: "\F0C7";
}

.mdi-border-bottom:before {
  content: "\F0C8";
}

.mdi-border-color:before {
  content: "\F0C9";
}

.mdi-border-horizontal:before {
  content: "\F0CA";
}

.mdi-border-inside:before {
  content: "\F0CB";
}

.mdi-border-left:before {
  content: "\F0CC";
}

.mdi-border-none:before {
  content: "\F0CD";
}

.mdi-border-outside:before {
  content: "\F0CE";
}

.mdi-border-right:before {
  content: "\F0CF";
}

.mdi-border-style:before {
  content: "\F0D0";
}

.mdi-border-top:before {
  content: "\F0D1";
}

.mdi-border-vertical:before {
  content: "\F0D2";
}

.mdi-bow-tie:before {
  content: "\F677";
}

.mdi-bowl:before {
  content: "\F617";
}

.mdi-bowling:before {
  content: "\F0D3";
}

.mdi-box:before {
  content: "\F0D4";
}

.mdi-box-cutter:before {
  content: "\F0D5";
}

.mdi-box-shadow:before {
  content: "\F637";
}

.mdi-bridge:before {
  content: "\F618";
}

.mdi-briefcase:before {
  content: "\F0D6";
}

.mdi-briefcase-check:before {
  content: "\F0D7";
}

.mdi-briefcase-download:before {
  content: "\F0D8";
}

.mdi-briefcase-upload:before {
  content: "\F0D9";
}

.mdi-brightness-1:before {
  content: "\F0DA";
}

.mdi-brightness-2:before {
  content: "\F0DB";
}

.mdi-brightness-3:before {
  content: "\F0DC";
}

.mdi-brightness-4:before {
  content: "\F0DD";
}

.mdi-brightness-5:before {
  content: "\F0DE";
}

.mdi-brightness-6:before {
  content: "\F0DF";
}

.mdi-brightness-7:before {
  content: "\F0E0";
}

.mdi-brightness-auto:before {
  content: "\F0E1";
}

.mdi-broom:before {
  content: "\F0E2";
}

.mdi-brush:before {
  content: "\F0E3";
}

.mdi-buffer:before {
  content: "\F619";
}

.mdi-bug:before {
  content: "\F0E4";
}

.mdi-bulletin-board:before {
  content: "\F0E5";
}

.mdi-bullhorn:before {
  content: "\F0E6";
}

.mdi-bullseye:before {
  content: "\F5DD";
}

.mdi-burst-mode:before {
  content: "\F5DE";
}

.mdi-bus:before {
  content: "\F0E7";
}

.mdi-bus-articulated-end:before {
  content: "\F79B";
}

.mdi-bus-articulated-front:before {
  content: "\F79C";
}

.mdi-bus-double-decker:before {
  content: "\F79D";
}

.mdi-bus-school:before {
  content: "\F79E";
}

.mdi-bus-side:before {
  content: "\F79F";
}

.mdi-cached:before {
  content: "\F0E8";
}

.mdi-cake:before {
  content: "\F0E9";
}

.mdi-cake-layered:before {
  content: "\F0EA";
}

.mdi-cake-variant:before {
  content: "\F0EB";
}

.mdi-calculator:before {
  content: "\F0EC";
}

.mdi-calendar:before {
  content: "\F0ED";
}

.mdi-calendar-blank:before {
  content: "\F0EE";
}

.mdi-calendar-check:before {
  content: "\F0EF";
}

.mdi-calendar-clock:before {
  content: "\F0F0";
}

.mdi-calendar-multiple:before {
  content: "\F0F1";
}

.mdi-calendar-multiple-check:before {
  content: "\F0F2";
}

.mdi-calendar-plus:before {
  content: "\F0F3";
}

.mdi-calendar-question:before {
  content: "\F691";
}

.mdi-calendar-range:before {
  content: "\F678";
}

.mdi-calendar-remove:before {
  content: "\F0F4";
}

.mdi-calendar-text:before {
  content: "\F0F5";
}

.mdi-calendar-today:before {
  content: "\F0F6";
}

.mdi-call-made:before {
  content: "\F0F7";
}

.mdi-call-merge:before {
  content: "\F0F8";
}

.mdi-call-missed:before {
  content: "\F0F9";
}

.mdi-call-received:before {
  content: "\F0FA";
}

.mdi-call-split:before {
  content: "\F0FB";
}

.mdi-camcorder:before {
  content: "\F0FC";
}

.mdi-camcorder-box:before {
  content: "\F0FD";
}

.mdi-camcorder-box-off:before {
  content: "\F0FE";
}

.mdi-camcorder-off:before {
  content: "\F0FF";
}

.mdi-camera:before {
  content: "\F100";
}

.mdi-camera-burst:before {
  content: "\F692";
}

.mdi-camera-enhance:before {
  content: "\F101";
}

.mdi-camera-front:before {
  content: "\F102";
}

.mdi-camera-front-variant:before {
  content: "\F103";
}

.mdi-camera-gopro:before {
  content: "\F7A0";
}

.mdi-camera-iris:before {
  content: "\F104";
}

.mdi-camera-metering-center:before {
  content: "\F7A1";
}

.mdi-camera-metering-matrix:before {
  content: "\F7A2";
}

.mdi-camera-metering-partial:before {
  content: "\F7A3";
}

.mdi-camera-metering-spot:before {
  content: "\F7A4";
}

.mdi-camera-off:before {
  content: "\F5DF";
}

.mdi-camera-party-mode:before {
  content: "\F105";
}

.mdi-camera-rear:before {
  content: "\F106";
}

.mdi-camera-rear-variant:before {
  content: "\F107";
}

.mdi-camera-switch:before {
  content: "\F108";
}

.mdi-camera-timer:before {
  content: "\F109";
}

.mdi-cancel:before {
  content: "\F739";
}

.mdi-candle:before {
  content: "\F5E2";
}

.mdi-candycane:before {
  content: "\F10A";
}

.mdi-cannabis:before {
  content: "\F7A5";
}

.mdi-car:before {
  content: "\F10B";
}

.mdi-car-battery:before {
  content: "\F10C";
}

.mdi-car-connected:before {
  content: "\F10D";
}

.mdi-car-convertable:before {
  content: "\F7A6";
}

.mdi-car-estate:before {
  content: "\F7A7";
}

.mdi-car-hatchback:before {
  content: "\F7A8";
}

.mdi-car-pickup:before {
  content: "\F7A9";
}

.mdi-car-side:before {
  content: "\F7AA";
}

.mdi-car-sports:before {
  content: "\F7AB";
}

.mdi-car-wash:before {
  content: "\F10E";
}

.mdi-caravan:before {
  content: "\F7AC";
}

.mdi-cards:before {
  content: "\F638";
}

.mdi-cards-outline:before {
  content: "\F639";
}

.mdi-cards-playing-outline:before {
  content: "\F63A";
}

.mdi-cards-variant:before {
  content: "\F6C6";
}

.mdi-carrot:before {
  content: "\F10F";
}

.mdi-cart:before {
  content: "\F110";
}

.mdi-cart-off:before {
  content: "\F66B";
}

.mdi-cart-outline:before {
  content: "\F111";
}

.mdi-cart-plus:before {
  content: "\F112";
}

.mdi-case-sensitive-alt:before {
  content: "\F113";
}

.mdi-cash:before {
  content: "\F114";
}

.mdi-cash-100:before {
  content: "\F115";
}

.mdi-cash-multiple:before {
  content: "\F116";
}

.mdi-cash-usd:before {
  content: "\F117";
}

.mdi-cast:before {
  content: "\F118";
}

.mdi-cast-connected:before {
  content: "\F119";
}

.mdi-cast-off:before {
  content: "\F789";
}

.mdi-castle:before {
  content: "\F11A";
}

.mdi-cat:before {
  content: "\F11B";
}

.mdi-cctv:before {
  content: "\F7AD";
}

.mdi-ceiling-light:before {
  content: "\F768";
}

.mdi-cellphone:before {
  content: "\F11C";
}

.mdi-cellphone-android:before {
  content: "\F11D";
}

.mdi-cellphone-basic:before {
  content: "\F11E";
}

.mdi-cellphone-dock:before {
  content: "\F11F";
}

.mdi-cellphone-iphone:before {
  content: "\F120";
}

.mdi-cellphone-link:before {
  content: "\F121";
}

.mdi-cellphone-link-off:before {
  content: "\F122";
}

.mdi-cellphone-settings:before {
  content: "\F123";
}

.mdi-certificate:before {
  content: "\F124";
}

.mdi-chair-school:before {
  content: "\F125";
}

.mdi-chart-arc:before {
  content: "\F126";
}

.mdi-chart-areaspline:before {
  content: "\F127";
}

.mdi-chart-bar:before {
  content: "\F128";
}

.mdi-chart-bar-stacked:before {
  content: "\F769";
}

.mdi-chart-bubble:before {
  content: "\F5E3";
}

.mdi-chart-donut:before {
  content: "\F7AE";
}

.mdi-chart-donut-variant:before {
  content: "\F7AF";
}

.mdi-chart-gantt:before {
  content: "\F66C";
}

.mdi-chart-histogram:before {
  content: "\F129";
}

.mdi-chart-line:before {
  content: "\F12A";
}

.mdi-chart-line-stacked:before {
  content: "\F76A";
}

.mdi-chart-line-variant:before {
  content: "\F7B0";
}

.mdi-chart-pie:before {
  content: "\F12B";
}

.mdi-chart-scatterplot-hexbin:before {
  content: "\F66D";
}

.mdi-chart-timeline:before {
  content: "\F66E";
}

.mdi-check:before {
  content: "\F12C";
}

.mdi-check-all:before {
  content: "\F12D";
}

.mdi-check-circle:before {
  content: "\F5E0";
}

.mdi-check-circle-outline:before {
  content: "\F5E1";
}

.mdi-checkbox-blank:before {
  content: "\F12E";
}

.mdi-checkbox-blank-circle:before {
  content: "\F12F";
}

.mdi-checkbox-blank-circle-outline:before {
  content: "\F130";
}

.mdi-checkbox-blank-outline:before {
  content: "\F131";
}

.mdi-checkbox-marked:before {
  content: "\F132";
}

.mdi-checkbox-marked-circle:before {
  content: "\F133";
}

.mdi-checkbox-marked-circle-outline:before {
  content: "\F134";
}

.mdi-checkbox-marked-outline:before {
  content: "\F135";
}

.mdi-checkbox-multiple-blank:before {
  content: "\F136";
}

.mdi-checkbox-multiple-blank-circle:before {
  content: "\F63B";
}

.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "\F63C";
}

.mdi-checkbox-multiple-blank-outline:before {
  content: "\F137";
}

.mdi-checkbox-multiple-marked:before {
  content: "\F138";
}

.mdi-checkbox-multiple-marked-circle:before {
  content: "\F63D";
}

.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "\F63E";
}

.mdi-checkbox-multiple-marked-outline:before {
  content: "\F139";
}

.mdi-checkerboard:before {
  content: "\F13A";
}

.mdi-chemical-weapon:before {
  content: "\F13B";
}

.mdi-chevron-double-down:before {
  content: "\F13C";
}

.mdi-chevron-double-left:before {
  content: "\F13D";
}

.mdi-chevron-double-right:before {
  content: "\F13E";
}

.mdi-chevron-double-up:before {
  content: "\F13F";
}

.mdi-chevron-down:before {
  content: "\F140";
}

.mdi-chevron-left:before {
  content: "\F141";
}

.mdi-chevron-right:before {
  content: "\F142";
}

.mdi-chevron-up:before {
  content: "\F143";
}

.mdi-chili-hot:before {
  content: "\F7B1";
}

.mdi-chili-medium:before {
  content: "\F7B2";
}

.mdi-chili-mild:before {
  content: "\F7B3";
}

.mdi-chip:before {
  content: "\F61A";
}

.mdi-church:before {
  content: "\F144";
}

.mdi-circle:before {
  content: "\F764";
}

.mdi-circle-outline:before {
  content: "\F765";
}

.mdi-cisco-webex:before {
  content: "\F145";
}

.mdi-city:before {
  content: "\F146";
}

.mdi-clipboard:before {
  content: "\F147";
}

.mdi-clipboard-account:before {
  content: "\F148";
}

.mdi-clipboard-alert:before {
  content: "\F149";
}

.mdi-clipboard-arrow-down:before {
  content: "\F14A";
}

.mdi-clipboard-arrow-left:before {
  content: "\F14B";
}

.mdi-clipboard-check:before {
  content: "\F14C";
}

.mdi-clipboard-flow:before {
  content: "\F6C7";
}

.mdi-clipboard-outline:before {
  content: "\F14D";
}

.mdi-clipboard-plus:before {
  content: "\F750";
}

.mdi-clipboard-text:before {
  content: "\F14E";
}

.mdi-clippy:before {
  content: "\F14F";
}

.mdi-clock:before {
  content: "\F150";
}

.mdi-clock-alert:before {
  content: "\F5CE";
}

.mdi-clock-end:before {
  content: "\F151";
}

.mdi-clock-fast:before {
  content: "\F152";
}

.mdi-clock-in:before {
  content: "\F153";
}

.mdi-clock-out:before {
  content: "\F154";
}

.mdi-clock-start:before {
  content: "\F155";
}

.mdi-close:before {
  content: "\F156";
}

.mdi-close-box:before {
  content: "\F157";
}

.mdi-close-box-outline:before {
  content: "\F158";
}

.mdi-close-circle:before {
  content: "\F159";
}

.mdi-close-circle-outline:before {
  content: "\F15A";
}

.mdi-close-network:before {
  content: "\F15B";
}

.mdi-close-octagon:before {
  content: "\F15C";
}

.mdi-close-octagon-outline:before {
  content: "\F15D";
}

.mdi-close-outline:before {
  content: "\F6C8";
}

.mdi-closed-caption:before {
  content: "\F15E";
}

.mdi-cloud:before {
  content: "\F15F";
}

.mdi-cloud-braces:before {
  content: "\F7B4";
}

.mdi-cloud-check:before {
  content: "\F160";
}

.mdi-cloud-circle:before {
  content: "\F161";
}

.mdi-cloud-download:before {
  content: "\F162";
}

.mdi-cloud-off-outline:before {
  content: "\F164";
}

.mdi-cloud-outline:before {
  content: "\F163";
}

.mdi-cloud-print:before {
  content: "\F165";
}

.mdi-cloud-print-outline:before {
  content: "\F166";
}

.mdi-cloud-sync:before {
  content: "\F63F";
}

.mdi-cloud-tags:before {
  content: "\F7B5";
}

.mdi-cloud-upload:before {
  content: "\F167";
}

.mdi-code-array:before {
  content: "\F168";
}

.mdi-code-braces:before {
  content: "\F169";
}

.mdi-code-brackets:before {
  content: "\F16A";
}

.mdi-code-equal:before {
  content: "\F16B";
}

.mdi-code-greater-than:before {
  content: "\F16C";
}

.mdi-code-greater-than-or-equal:before {
  content: "\F16D";
}

.mdi-code-less-than:before {
  content: "\F16E";
}

.mdi-code-less-than-or-equal:before {
  content: "\F16F";
}

.mdi-code-not-equal:before {
  content: "\F170";
}

.mdi-code-not-equal-variant:before {
  content: "\F171";
}

.mdi-code-parentheses:before {
  content: "\F172";
}

.mdi-code-string:before {
  content: "\F173";
}

.mdi-code-tags:before {
  content: "\F174";
}

.mdi-code-tags-check:before {
  content: "\F693";
}

.mdi-codepen:before {
  content: "\F175";
}

.mdi-coffee:before {
  content: "\F176";
}

.mdi-coffee-outline:before {
  content: "\F6C9";
}

.mdi-coffee-to-go:before {
  content: "\F177";
}

.mdi-coin:before {
  content: "\F178";
}

.mdi-coins:before {
  content: "\F694";
}

.mdi-collage:before {
  content: "\F640";
}

.mdi-color-helper:before {
  content: "\F179";
}

.mdi-comment:before {
  content: "\F17A";
}

.mdi-comment-account:before {
  content: "\F17B";
}

.mdi-comment-account-outline:before {
  content: "\F17C";
}

.mdi-comment-alert:before {
  content: "\F17D";
}

.mdi-comment-alert-outline:before {
  content: "\F17E";
}

.mdi-comment-check:before {
  content: "\F17F";
}

.mdi-comment-check-outline:before {
  content: "\F180";
}

.mdi-comment-multiple-outline:before {
  content: "\F181";
}

.mdi-comment-outline:before {
  content: "\F182";
}

.mdi-comment-plus-outline:before {
  content: "\F183";
}

.mdi-comment-processing:before {
  content: "\F184";
}

.mdi-comment-processing-outline:before {
  content: "\F185";
}

.mdi-comment-question-outline:before {
  content: "\F186";
}

.mdi-comment-remove-outline:before {
  content: "\F187";
}

.mdi-comment-text:before {
  content: "\F188";
}

.mdi-comment-text-outline:before {
  content: "\F189";
}

.mdi-compare:before {
  content: "\F18A";
}

.mdi-compass:before {
  content: "\F18B";
}

.mdi-compass-outline:before {
  content: "\F18C";
}

.mdi-console:before {
  content: "\F18D";
}

.mdi-console-line:before {
  content: "\F7B6";
}

.mdi-contact-mail:before {
  content: "\F18E";
}

.mdi-contacts:before {
  content: "\F6CA";
}

.mdi-content-copy:before {
  content: "\F18F";
}

.mdi-content-cut:before {
  content: "\F190";
}

.mdi-content-duplicate:before {
  content: "\F191";
}

.mdi-content-paste:before {
  content: "\F192";
}

.mdi-content-save:before {
  content: "\F193";
}

.mdi-content-save-all:before {
  content: "\F194";
}

.mdi-content-save-settings:before {
  content: "\F61B";
}

.mdi-contrast:before {
  content: "\F195";
}

.mdi-contrast-box:before {
  content: "\F196";
}

.mdi-contrast-circle:before {
  content: "\F197";
}

.mdi-cookie:before {
  content: "\F198";
}

.mdi-copyright:before {
  content: "\F5E6";
}

.mdi-corn:before {
  content: "\F7B7";
}

.mdi-counter:before {
  content: "\F199";
}

.mdi-cow:before {
  content: "\F19A";
}

.mdi-creation:before {
  content: "\F1C9";
}

.mdi-credit-card:before {
  content: "\F19B";
}

.mdi-credit-card-multiple:before {
  content: "\F19C";
}

.mdi-credit-card-off:before {
  content: "\F5E4";
}

.mdi-credit-card-plus:before {
  content: "\F675";
}

.mdi-credit-card-scan:before {
  content: "\F19D";
}

.mdi-crop:before {
  content: "\F19E";
}

.mdi-crop-free:before {
  content: "\F19F";
}

.mdi-crop-landscape:before {
  content: "\F1A0";
}

.mdi-crop-portrait:before {
  content: "\F1A1";
}

.mdi-crop-rotate:before {
  content: "\F695";
}

.mdi-crop-square:before {
  content: "\F1A2";
}

.mdi-crosshairs:before {
  content: "\F1A3";
}

.mdi-crosshairs-gps:before {
  content: "\F1A4";
}

.mdi-crown:before {
  content: "\F1A5";
}

.mdi-cube:before {
  content: "\F1A6";
}

.mdi-cube-outline:before {
  content: "\F1A7";
}

.mdi-cube-send:before {
  content: "\F1A8";
}

.mdi-cube-unfolded:before {
  content: "\F1A9";
}

.mdi-cup:before {
  content: "\F1AA";
}

.mdi-cup-off:before {
  content: "\F5E5";
}

.mdi-cup-water:before {
  content: "\F1AB";
}

.mdi-currency-btc:before {
  content: "\F1AC";
}

.mdi-currency-chf:before {
  content: "\F7B8";
}

.mdi-currency-cny:before {
  content: "\F7B9";
}

.mdi-currency-eth:before {
  content: "\F7BA";
}

.mdi-currency-eur:before {
  content: "\F1AD";
}

.mdi-currency-gbp:before {
  content: "\F1AE";
}

.mdi-currency-inr:before {
  content: "\F1AF";
}

.mdi-currency-jpy:before {
  content: "\F7BB";
}

.mdi-currency-krw:before {
  content: "\F7BC";
}

.mdi-currency-ngn:before {
  content: "\F1B0";
}

.mdi-currency-rub:before {
  content: "\F1B1";
}

.mdi-currency-sign:before {
  content: "\F7BD";
}

.mdi-currency-try:before {
  content: "\F1B2";
}

.mdi-currency-twd:before {
  content: "\F7BE";
}

.mdi-currency-usd:before {
  content: "\F1B3";
}

.mdi-currency-usd-off:before {
  content: "\F679";
}

.mdi-cursor-default:before {
  content: "\F1B4";
}

.mdi-cursor-default-outline:before {
  content: "\F1B5";
}

.mdi-cursor-move:before {
  content: "\F1B6";
}

.mdi-cursor-pointer:before {
  content: "\F1B7";
}

.mdi-cursor-text:before {
  content: "\F5E7";
}

.mdi-database:before {
  content: "\F1B8";
}

.mdi-database-minus:before {
  content: "\F1B9";
}

.mdi-database-plus:before {
  content: "\F1BA";
}

.mdi-debug-step-into:before {
  content: "\F1BB";
}

.mdi-debug-step-out:before {
  content: "\F1BC";
}

.mdi-debug-step-over:before {
  content: "\F1BD";
}

.mdi-decagram:before {
  content: "\F76B";
}

.mdi-decagram-outline:before {
  content: "\F76C";
}

.mdi-decimal-decrease:before {
  content: "\F1BE";
}

.mdi-decimal-increase:before {
  content: "\F1BF";
}

.mdi-delete:before {
  content: "\F1C0";
}

.mdi-delete-circle:before {
  content: "\F682";
}

.mdi-delete-empty:before {
  content: "\F6CB";
}

.mdi-delete-forever:before {
  content: "\F5E8";
}

.mdi-delete-sweep:before {
  content: "\F5E9";
}

.mdi-delete-variant:before {
  content: "\F1C1";
}

.mdi-delta:before {
  content: "\F1C2";
}

.mdi-deskphone:before {
  content: "\F1C3";
}

.mdi-desktop-classic:before {
  content: "\F7BF";
}

.mdi-desktop-mac:before {
  content: "\F1C4";
}

.mdi-desktop-tower:before {
  content: "\F1C5";
}

.mdi-details:before {
  content: "\F1C6";
}

.mdi-developer-board:before {
  content: "\F696";
}

.mdi-deviantart:before {
  content: "\F1C7";
}

.mdi-dialpad:before {
  content: "\F61C";
}

.mdi-diamond:before {
  content: "\F1C8";
}

.mdi-dice-1:before {
  content: "\F1CA";
}

.mdi-dice-2:before {
  content: "\F1CB";
}

.mdi-dice-3:before {
  content: "\F1CC";
}

.mdi-dice-4:before {
  content: "\F1CD";
}

.mdi-dice-5:before {
  content: "\F1CE";
}

.mdi-dice-6:before {
  content: "\F1CF";
}

.mdi-dice-d10:before {
  content: "\F76E";
}

.mdi-dice-d20:before {
  content: "\F5EA";
}

.mdi-dice-d4:before {
  content: "\F5EB";
}

.mdi-dice-d6:before {
  content: "\F5EC";
}

.mdi-dice-d8:before {
  content: "\F5ED";
}

.mdi-dice-multiple:before {
  content: "\F76D";
}

.mdi-dictionary:before {
  content: "\F61D";
}

.mdi-dip-switch:before {
  content: "\F7C0";
}

.mdi-directions:before {
  content: "\F1D0";
}

.mdi-directions-fork:before {
  content: "\F641";
}

.mdi-discord:before {
  content: "\F66F";
}

.mdi-disk:before {
  content: "\F5EE";
}

.mdi-disk-alert:before {
  content: "\F1D1";
}

.mdi-disqus:before {
  content: "\F1D2";
}

.mdi-disqus-outline:before {
  content: "\F1D3";
}

.mdi-division:before {
  content: "\F1D4";
}

.mdi-division-box:before {
  content: "\F1D5";
}

.mdi-dna:before {
  content: "\F683";
}

.mdi-dns:before {
  content: "\F1D6";
}

.mdi-do-not-disturb:before {
  content: "\F697";
}

.mdi-do-not-disturb-off:before {
  content: "\F698";
}

.mdi-dolby:before {
  content: "\F6B2";
}

.mdi-domain:before {
  content: "\F1D7";
}

.mdi-donkey:before {
  content: "\F7C1";
}

.mdi-dots-horizontal:before {
  content: "\F1D8";
}

.mdi-dots-horizontal-circle:before {
  content: "\F7C2";
}

.mdi-dots-vertical:before {
  content: "\F1D9";
}

.mdi-dots-vertical-circle:before {
  content: "\F7C3";
}

.mdi-douban:before {
  content: "\F699";
}

.mdi-download:before {
  content: "\F1DA";
}

.mdi-download-network:before {
  content: "\F6F3";
}

.mdi-drag:before {
  content: "\F1DB";
}

.mdi-drag-horizontal:before {
  content: "\F1DC";
}

.mdi-drag-vertical:before {
  content: "\F1DD";
}

.mdi-drawing:before {
  content: "\F1DE";
}

.mdi-drawing-box:before {
  content: "\F1DF";
}

.mdi-dribbble:before {
  content: "\F1E0";
}

.mdi-dribbble-box:before {
  content: "\F1E1";
}

.mdi-drone:before {
  content: "\F1E2";
}

.mdi-dropbox:before {
  content: "\F1E3";
}

.mdi-drupal:before {
  content: "\F1E4";
}

.mdi-duck:before {
  content: "\F1E5";
}

.mdi-dumbbell:before {
  content: "\F1E6";
}

.mdi-ear-hearing:before {
  content: "\F7C4";
}

.mdi-earth:before {
  content: "\F1E7";
}

.mdi-earth-box:before {
  content: "\F6CC";
}

.mdi-earth-box-off:before {
  content: "\F6CD";
}

.mdi-earth-off:before {
  content: "\F1E8";
}

.mdi-edge:before {
  content: "\F1E9";
}

.mdi-eject:before {
  content: "\F1EA";
}

.mdi-elephant:before {
  content: "\F7C5";
}

.mdi-elevation-decline:before {
  content: "\F1EB";
}

.mdi-elevation-rise:before {
  content: "\F1EC";
}

.mdi-elevator:before {
  content: "\F1ED";
}

.mdi-email:before {
  content: "\F1EE";
}

.mdi-email-alert:before {
  content: "\F6CE";
}

.mdi-email-open:before {
  content: "\F1EF";
}

.mdi-email-open-outline:before {
  content: "\F5EF";
}

.mdi-email-outline:before {
  content: "\F1F0";
}

.mdi-email-secure:before {
  content: "\F1F1";
}

.mdi-email-variant:before {
  content: "\F5F0";
}

.mdi-emby:before {
  content: "\F6B3";
}

.mdi-emoticon:before {
  content: "\F1F2";
}

.mdi-emoticon-cool:before {
  content: "\F1F3";
}

.mdi-emoticon-dead:before {
  content: "\F69A";
}

.mdi-emoticon-devil:before {
  content: "\F1F4";
}

.mdi-emoticon-excited:before {
  content: "\F69B";
}

.mdi-emoticon-happy:before {
  content: "\F1F5";
}

.mdi-emoticon-neutral:before {
  content: "\F1F6";
}

.mdi-emoticon-poop:before {
  content: "\F1F7";
}

.mdi-emoticon-sad:before {
  content: "\F1F8";
}

.mdi-emoticon-tongue:before {
  content: "\F1F9";
}

.mdi-engine:before {
  content: "\F1FA";
}

.mdi-engine-outline:before {
  content: "\F1FB";
}

.mdi-equal:before {
  content: "\F1FC";
}

.mdi-equal-box:before {
  content: "\F1FD";
}

.mdi-eraser:before {
  content: "\F1FE";
}

.mdi-eraser-variant:before {
  content: "\F642";
}

.mdi-escalator:before {
  content: "\F1FF";
}

.mdi-ethernet:before {
  content: "\F200";
}

.mdi-ethernet-cable:before {
  content: "\F201";
}

.mdi-ethernet-cable-off:before {
  content: "\F202";
}

.mdi-etsy:before {
  content: "\F203";
}

.mdi-ev-station:before {
  content: "\F5F1";
}

.mdi-eventbrite:before {
  content: "\F7C6";
}

.mdi-evernote:before {
  content: "\F204";
}

.mdi-exclamation:before {
  content: "\F205";
}

.mdi-exit-to-app:before {
  content: "\F206";
}

.mdi-export:before {
  content: "\F207";
}

.mdi-eye:before {
  content: "\F208";
}

.mdi-eye-off:before {
  content: "\F209";
}

.mdi-eye-off-outline:before {
  content: "\F6D0";
}

.mdi-eye-outline:before {
  content: "\F6CF";
}

.mdi-eyedropper:before {
  content: "\F20A";
}

.mdi-eyedropper-variant:before {
  content: "\F20B";
}

.mdi-face:before {
  content: "\F643";
}

.mdi-face-profile:before {
  content: "\F644";
}

.mdi-facebook:before {
  content: "\F20C";
}

.mdi-facebook-box:before {
  content: "\F20D";
}

.mdi-facebook-messenger:before {
  content: "\F20E";
}

.mdi-factory:before {
  content: "\F20F";
}

.mdi-fan:before {
  content: "\F210";
}

.mdi-fast-forward:before {
  content: "\F211";
}

.mdi-fast-forward-outline:before {
  content: "\F6D1";
}

.mdi-fax:before {
  content: "\F212";
}

.mdi-feather:before {
  content: "\F6D2";
}

.mdi-ferry:before {
  content: "\F213";
}

.mdi-file:before {
  content: "\F214";
}

.mdi-file-account:before {
  content: "\F73A";
}

.mdi-file-chart:before {
  content: "\F215";
}

.mdi-file-check:before {
  content: "\F216";
}

.mdi-file-cloud:before {
  content: "\F217";
}

.mdi-file-delimited:before {
  content: "\F218";
}

.mdi-file-document:before {
  content: "\F219";
}

.mdi-file-document-box:before {
  content: "\F21A";
}

.mdi-file-excel:before {
  content: "\F21B";
}

.mdi-file-excel-box:before {
  content: "\F21C";
}

.mdi-file-export:before {
  content: "\F21D";
}

.mdi-file-find:before {
  content: "\F21E";
}

.mdi-file-hidden:before {
  content: "\F613";
}

.mdi-file-image:before {
  content: "\F21F";
}

.mdi-file-import:before {
  content: "\F220";
}

.mdi-file-lock:before {
  content: "\F221";
}

.mdi-file-multiple:before {
  content: "\F222";
}

.mdi-file-music:before {
  content: "\F223";
}

.mdi-file-outline:before {
  content: "\F224";
}

.mdi-file-pdf:before {
  content: "\F225";
}

.mdi-file-pdf-box:before {
  content: "\F226";
}

.mdi-file-plus:before {
  content: "\F751";
}

.mdi-file-powerpoint:before {
  content: "\F227";
}

.mdi-file-powerpoint-box:before {
  content: "\F228";
}

.mdi-file-presentation-box:before {
  content: "\F229";
}

.mdi-file-restore:before {
  content: "\F670";
}

.mdi-file-send:before {
  content: "\F22A";
}

.mdi-file-tree:before {
  content: "\F645";
}

.mdi-file-video:before {
  content: "\F22B";
}

.mdi-file-word:before {
  content: "\F22C";
}

.mdi-file-word-box:before {
  content: "\F22D";
}

.mdi-file-xml:before {
  content: "\F22E";
}

.mdi-film:before {
  content: "\F22F";
}

.mdi-filmstrip:before {
  content: "\F230";
}

.mdi-filmstrip-off:before {
  content: "\F231";
}

.mdi-filter:before {
  content: "\F232";
}

.mdi-filter-outline:before {
  content: "\F233";
}

.mdi-filter-remove:before {
  content: "\F234";
}

.mdi-filter-remove-outline:before {
  content: "\F235";
}

.mdi-filter-variant:before {
  content: "\F236";
}

.mdi-find-replace:before {
  content: "\F6D3";
}

.mdi-fingerprint:before {
  content: "\F237";
}

.mdi-fire:before {
  content: "\F238";
}

.mdi-firefox:before {
  content: "\F239";
}

.mdi-fish:before {
  content: "\F23A";
}

.mdi-flag:before {
  content: "\F23B";
}

.mdi-flag-checkered:before {
  content: "\F23C";
}

.mdi-flag-outline:before {
  content: "\F23D";
}

.mdi-flag-outline-variant:before {
  content: "\F23E";
}

.mdi-flag-triangle:before {
  content: "\F23F";
}

.mdi-flag-variant:before {
  content: "\F240";
}

.mdi-flash:before {
  content: "\F241";
}

.mdi-flash-auto:before {
  content: "\F242";
}

.mdi-flash-off:before {
  content: "\F243";
}

.mdi-flash-outline:before {
  content: "\F6D4";
}

.mdi-flash-red-eye:before {
  content: "\F67A";
}

.mdi-flashlight:before {
  content: "\F244";
}

.mdi-flashlight-off:before {
  content: "\F245";
}

.mdi-flask:before {
  content: "\F093";
}

.mdi-flask-empty:before {
  content: "\F094";
}

.mdi-flask-empty-outline:before {
  content: "\F095";
}

.mdi-flask-outline:before {
  content: "\F096";
}

.mdi-flattr:before {
  content: "\F246";
}

.mdi-flip-to-back:before {
  content: "\F247";
}

.mdi-flip-to-front:before {
  content: "\F248";
}

.mdi-floppy:before {
  content: "\F249";
}

.mdi-flower:before {
  content: "\F24A";
}

.mdi-folder:before {
  content: "\F24B";
}

.mdi-folder-account:before {
  content: "\F24C";
}

.mdi-folder-download:before {
  content: "\F24D";
}

.mdi-folder-google-drive:before {
  content: "\F24E";
}

.mdi-folder-image:before {
  content: "\F24F";
}

.mdi-folder-lock:before {
  content: "\F250";
}

.mdi-folder-lock-open:before {
  content: "\F251";
}

.mdi-folder-move:before {
  content: "\F252";
}

.mdi-folder-multiple:before {
  content: "\F253";
}

.mdi-folder-multiple-image:before {
  content: "\F254";
}

.mdi-folder-multiple-outline:before {
  content: "\F255";
}

.mdi-folder-open:before {
  content: "\F76F";
}

.mdi-folder-outline:before {
  content: "\F256";
}

.mdi-folder-plus:before {
  content: "\F257";
}

.mdi-folder-remove:before {
  content: "\F258";
}

.mdi-folder-star:before {
  content: "\F69C";
}

.mdi-folder-upload:before {
  content: "\F259";
}

.mdi-font-awesome:before {
  content: "\F03A";
}

.mdi-food:before {
  content: "\F25A";
}

.mdi-food-apple:before {
  content: "\F25B";
}

.mdi-food-croissant:before {
  content: "\F7C7";
}

.mdi-food-fork-drink:before {
  content: "\F5F2";
}

.mdi-food-off:before {
  content: "\F5F3";
}

.mdi-food-variant:before {
  content: "\F25C";
}

.mdi-football:before {
  content: "\F25D";
}

.mdi-football-australian:before {
  content: "\F25E";
}

.mdi-football-helmet:before {
  content: "\F25F";
}

.mdi-forklift:before {
  content: "\F7C8";
}

.mdi-format-align-bottom:before {
  content: "\F752";
}

.mdi-format-align-center:before {
  content: "\F260";
}

.mdi-format-align-justify:before {
  content: "\F261";
}

.mdi-format-align-left:before {
  content: "\F262";
}

.mdi-format-align-middle:before {
  content: "\F753";
}

.mdi-format-align-right:before {
  content: "\F263";
}

.mdi-format-align-top:before {
  content: "\F754";
}

.mdi-format-annotation-plus:before {
  content: "\F646";
}

.mdi-format-bold:before {
  content: "\F264";
}

.mdi-format-clear:before {
  content: "\F265";
}

.mdi-format-color-fill:before {
  content: "\F266";
}

.mdi-format-color-text:before {
  content: "\F69D";
}

.mdi-format-float-center:before {
  content: "\F267";
}

.mdi-format-float-left:before {
  content: "\F268";
}

.mdi-format-float-none:before {
  content: "\F269";
}

.mdi-format-float-right:before {
  content: "\F26A";
}

.mdi-format-font:before {
  content: "\F6D5";
}

.mdi-format-header-1:before {
  content: "\F26B";
}

.mdi-format-header-2:before {
  content: "\F26C";
}

.mdi-format-header-3:before {
  content: "\F26D";
}

.mdi-format-header-4:before {
  content: "\F26E";
}

.mdi-format-header-5:before {
  content: "\F26F";
}

.mdi-format-header-6:before {
  content: "\F270";
}

.mdi-format-header-decrease:before {
  content: "\F271";
}

.mdi-format-header-equal:before {
  content: "\F272";
}

.mdi-format-header-increase:before {
  content: "\F273";
}

.mdi-format-header-pound:before {
  content: "\F274";
}

.mdi-format-horizontal-align-center:before {
  content: "\F61E";
}

.mdi-format-horizontal-align-left:before {
  content: "\F61F";
}

.mdi-format-horizontal-align-right:before {
  content: "\F620";
}

.mdi-format-indent-decrease:before {
  content: "\F275";
}

.mdi-format-indent-increase:before {
  content: "\F276";
}

.mdi-format-italic:before {
  content: "\F277";
}

.mdi-format-line-spacing:before {
  content: "\F278";
}

.mdi-format-line-style:before {
  content: "\F5C8";
}

.mdi-format-line-weight:before {
  content: "\F5C9";
}

.mdi-format-list-bulleted:before {
  content: "\F279";
}

.mdi-format-list-bulleted-type:before {
  content: "\F27A";
}

.mdi-format-list-checks:before {
  content: "\F755";
}

.mdi-format-list-numbers:before {
  content: "\F27B";
}

.mdi-format-page-break:before {
  content: "\F6D6";
}

.mdi-format-paint:before {
  content: "\F27C";
}

.mdi-format-paragraph:before {
  content: "\F27D";
}

.mdi-format-pilcrow:before {
  content: "\F6D7";
}

.mdi-format-quote-close:before {
  content: "\F27E";
}

.mdi-format-quote-open:before {
  content: "\F756";
}

.mdi-format-rotate-90:before {
  content: "\F6A9";
}

.mdi-format-section:before {
  content: "\F69E";
}

.mdi-format-size:before {
  content: "\F27F";
}

.mdi-format-strikethrough:before {
  content: "\F280";
}

.mdi-format-strikethrough-variant:before {
  content: "\F281";
}

.mdi-format-subscript:before {
  content: "\F282";
}

.mdi-format-superscript:before {
  content: "\F283";
}

.mdi-format-text:before {
  content: "\F284";
}

.mdi-format-textdirection-l-to-r:before {
  content: "\F285";
}

.mdi-format-textdirection-r-to-l:before {
  content: "\F286";
}

.mdi-format-title:before {
  content: "\F5F4";
}

.mdi-format-underline:before {
  content: "\F287";
}

.mdi-format-vertical-align-bottom:before {
  content: "\F621";
}

.mdi-format-vertical-align-center:before {
  content: "\F622";
}

.mdi-format-vertical-align-top:before {
  content: "\F623";
}

.mdi-format-wrap-inline:before {
  content: "\F288";
}

.mdi-format-wrap-square:before {
  content: "\F289";
}

.mdi-format-wrap-tight:before {
  content: "\F28A";
}

.mdi-format-wrap-top-bottom:before {
  content: "\F28B";
}

.mdi-forum:before {
  content: "\F28C";
}

.mdi-forward:before {
  content: "\F28D";
}

.mdi-foursquare:before {
  content: "\F28E";
}

.mdi-fridge:before {
  content: "\F28F";
}

.mdi-fridge-filled:before {
  content: "\F290";
}

.mdi-fridge-filled-bottom:before {
  content: "\F291";
}

.mdi-fridge-filled-top:before {
  content: "\F292";
}

.mdi-fuel:before {
  content: "\F7C9";
}

.mdi-fullscreen:before {
  content: "\F293";
}

.mdi-fullscreen-exit:before {
  content: "\F294";
}

.mdi-function:before {
  content: "\F295";
}

.mdi-gamepad:before {
  content: "\F296";
}

.mdi-gamepad-variant:before {
  content: "\F297";
}

.mdi-garage:before {
  content: "\F6D8";
}

.mdi-garage-open:before {
  content: "\F6D9";
}

.mdi-gas-cylinder:before {
  content: "\F647";
}

.mdi-gas-station:before {
  content: "\F298";
}

.mdi-gate:before {
  content: "\F299";
}

.mdi-gauge:before {
  content: "\F29A";
}

.mdi-gavel:before {
  content: "\F29B";
}

.mdi-gender-female:before {
  content: "\F29C";
}

.mdi-gender-male:before {
  content: "\F29D";
}

.mdi-gender-male-female:before {
  content: "\F29E";
}

.mdi-gender-transgender:before {
  content: "\F29F";
}

.mdi-gesture:before {
  content: "\F7CA";
}

.mdi-gesture-double-tap:before {
  content: "\F73B";
}

.mdi-gesture-swipe-down:before {
  content: "\F73C";
}

.mdi-gesture-swipe-left:before {
  content: "\F73D";
}

.mdi-gesture-swipe-right:before {
  content: "\F73E";
}

.mdi-gesture-swipe-up:before {
  content: "\F73F";
}

.mdi-gesture-tap:before {
  content: "\F740";
}

.mdi-gesture-two-double-tap:before {
  content: "\F741";
}

.mdi-gesture-two-tap:before {
  content: "\F742";
}

.mdi-ghost:before {
  content: "\F2A0";
}

.mdi-gift:before {
  content: "\F2A1";
}

.mdi-git:before {
  content: "\F2A2";
}

.mdi-github-box:before {
  content: "\F2A3";
}

.mdi-github-circle:before {
  content: "\F2A4";
}

.mdi-github-face:before {
  content: "\F6DA";
}

.mdi-glass-flute:before {
  content: "\F2A5";
}

.mdi-glass-mug:before {
  content: "\F2A6";
}

.mdi-glass-stange:before {
  content: "\F2A7";
}

.mdi-glass-tulip:before {
  content: "\F2A8";
}

.mdi-glassdoor:before {
  content: "\F2A9";
}

.mdi-glasses:before {
  content: "\F2AA";
}

.mdi-gmail:before {
  content: "\F2AB";
}

.mdi-gnome:before {
  content: "\F2AC";
}

.mdi-gondola:before {
  content: "\F685";
}

.mdi-google:before {
  content: "\F2AD";
}

.mdi-google-analytics:before {
  content: "\F7CB";
}

.mdi-google-assistant:before {
  content: "\F7CC";
}

.mdi-google-cardboard:before {
  content: "\F2AE";
}

.mdi-google-chrome:before {
  content: "\F2AF";
}

.mdi-google-circles:before {
  content: "\F2B0";
}

.mdi-google-circles-communities:before {
  content: "\F2B1";
}

.mdi-google-circles-extended:before {
  content: "\F2B2";
}

.mdi-google-circles-group:before {
  content: "\F2B3";
}

.mdi-google-controller:before {
  content: "\F2B4";
}

.mdi-google-controller-off:before {
  content: "\F2B5";
}

.mdi-google-drive:before {
  content: "\F2B6";
}

.mdi-google-earth:before {
  content: "\F2B7";
}

.mdi-google-glass:before {
  content: "\F2B8";
}

.mdi-google-keep:before {
  content: "\F6DB";
}

.mdi-google-maps:before {
  content: "\F5F5";
}

.mdi-google-nearby:before {
  content: "\F2B9";
}

.mdi-google-pages:before {
  content: "\F2BA";
}

.mdi-google-photos:before {
  content: "\F6DC";
}

.mdi-google-physical-web:before {
  content: "\F2BB";
}

.mdi-google-play:before {
  content: "\F2BC";
}

.mdi-google-plus:before {
  content: "\F2BD";
}

.mdi-google-plus-box:before {
  content: "\F2BE";
}

.mdi-google-translate:before {
  content: "\F2BF";
}

.mdi-google-wallet:before {
  content: "\F2C0";
}

.mdi-gradient:before {
  content: "\F69F";
}

.mdi-grease-pencil:before {
  content: "\F648";
}

.mdi-grid:before {
  content: "\F2C1";
}

.mdi-grid-large:before {
  content: "\F757";
}

.mdi-grid-off:before {
  content: "\F2C2";
}

.mdi-group:before {
  content: "\F2C3";
}

.mdi-guitar-acoustic:before {
  content: "\F770";
}

.mdi-guitar-electric:before {
  content: "\F2C4";
}

.mdi-guitar-pick:before {
  content: "\F2C5";
}

.mdi-guitar-pick-outline:before {
  content: "\F2C6";
}

.mdi-hackernews:before {
  content: "\F624";
}

.mdi-hamburger:before {
  content: "\F684";
}

.mdi-hand-pointing-right:before {
  content: "\F2C7";
}

.mdi-hanger:before {
  content: "\F2C8";
}

.mdi-hangouts:before {
  content: "\F2C9";
}

.mdi-harddisk:before {
  content: "\F2CA";
}

.mdi-headphones:before {
  content: "\F2CB";
}

.mdi-headphones-box:before {
  content: "\F2CC";
}

.mdi-headphones-off:before {
  content: "\F7CD";
}

.mdi-headphones-settings:before {
  content: "\F2CD";
}

.mdi-headset:before {
  content: "\F2CE";
}

.mdi-headset-dock:before {
  content: "\F2CF";
}

.mdi-headset-off:before {
  content: "\F2D0";
}

.mdi-heart:before {
  content: "\F2D1";
}

.mdi-heart-box:before {
  content: "\F2D2";
}

.mdi-heart-box-outline:before {
  content: "\F2D3";
}

.mdi-heart-broken:before {
  content: "\F2D4";
}

.mdi-heart-half:before {
  content: "\F6DE";
}

.mdi-heart-half-full:before {
  content: "\F6DD";
}

.mdi-heart-half-outline:before {
  content: "\F6DF";
}

.mdi-heart-off:before {
  content: "\F758";
}

.mdi-heart-outline:before {
  content: "\F2D5";
}

.mdi-heart-pulse:before {
  content: "\F5F6";
}

.mdi-help:before {
  content: "\F2D6";
}

.mdi-help-box:before {
  content: "\F78A";
}

.mdi-help-circle:before {
  content: "\F2D7";
}

.mdi-help-circle-outline:before {
  content: "\F625";
}

.mdi-help-network:before {
  content: "\F6F4";
}

.mdi-hexagon:before {
  content: "\F2D8";
}

.mdi-hexagon-multiple:before {
  content: "\F6E0";
}

.mdi-hexagon-outline:before {
  content: "\F2D9";
}

.mdi-high-definition:before {
  content: "\F7CE";
}

.mdi-highway:before {
  content: "\F5F7";
}

.mdi-history:before {
  content: "\F2DA";
}

.mdi-hololens:before {
  content: "\F2DB";
}

.mdi-home:before {
  content: "\F2DC";
}

.mdi-home-assistant:before {
  content: "\F7CF";
}

.mdi-home-automation:before {
  content: "\F7D0";
}

.mdi-home-circle:before {
  content: "\F7D1";
}

.mdi-home-map-marker:before {
  content: "\F5F8";
}

.mdi-home-modern:before {
  content: "\F2DD";
}

.mdi-home-outline:before {
  content: "\F6A0";
}

.mdi-home-variant:before {
  content: "\F2DE";
}

.mdi-hook:before {
  content: "\F6E1";
}

.mdi-hook-off:before {
  content: "\F6E2";
}

.mdi-hops:before {
  content: "\F2DF";
}

.mdi-hospital:before {
  content: "\F2E0";
}

.mdi-hospital-building:before {
  content: "\F2E1";
}

.mdi-hospital-marker:before {
  content: "\F2E2";
}

.mdi-hotel:before {
  content: "\F2E3";
}

.mdi-houzz:before {
  content: "\F2E4";
}

.mdi-houzz-box:before {
  content: "\F2E5";
}

.mdi-human:before {
  content: "\F2E6";
}

.mdi-human-child:before {
  content: "\F2E7";
}

.mdi-human-female:before {
  content: "\F649";
}

.mdi-human-greeting:before {
  content: "\F64A";
}

.mdi-human-handsdown:before {
  content: "\F64B";
}

.mdi-human-handsup:before {
  content: "\F64C";
}

.mdi-human-male:before {
  content: "\F64D";
}

.mdi-human-male-female:before {
  content: "\F2E8";
}

.mdi-human-pregnant:before {
  content: "\F5CF";
}

.mdi-humble-bundle:before {
  content: "\F743";
}

.mdi-image:before {
  content: "\F2E9";
}

.mdi-image-album:before {
  content: "\F2EA";
}

.mdi-image-area:before {
  content: "\F2EB";
}

.mdi-image-area-close:before {
  content: "\F2EC";
}

.mdi-image-broken:before {
  content: "\F2ED";
}

.mdi-image-broken-variant:before {
  content: "\F2EE";
}

.mdi-image-filter:before {
  content: "\F2EF";
}

.mdi-image-filter-black-white:before {
  content: "\F2F0";
}

.mdi-image-filter-center-focus:before {
  content: "\F2F1";
}

.mdi-image-filter-center-focus-weak:before {
  content: "\F2F2";
}

.mdi-image-filter-drama:before {
  content: "\F2F3";
}

.mdi-image-filter-frames:before {
  content: "\F2F4";
}

.mdi-image-filter-hdr:before {
  content: "\F2F5";
}

.mdi-image-filter-none:before {
  content: "\F2F6";
}

.mdi-image-filter-tilt-shift:before {
  content: "\F2F7";
}

.mdi-image-filter-vintage:before {
  content: "\F2F8";
}

.mdi-image-multiple:before {
  content: "\F2F9";
}

.mdi-import:before {
  content: "\F2FA";
}

.mdi-inbox:before {
  content: "\F686";
}

.mdi-inbox-arrow-down:before {
  content: "\F2FB";
}

.mdi-inbox-arrow-up:before {
  content: "\F3D1";
}

.mdi-incognito:before {
  content: "\F5F9";
}

.mdi-infinity:before {
  content: "\F6E3";
}

.mdi-information:before {
  content: "\F2FC";
}

.mdi-information-outline:before {
  content: "\F2FD";
}

.mdi-information-variant:before {
  content: "\F64E";
}

.mdi-instagram:before {
  content: "\F2FE";
}

.mdi-instapaper:before {
  content: "\F2FF";
}

.mdi-internet-explorer:before {
  content: "\F300";
}

.mdi-invert-colors:before {
  content: "\F301";
}

.mdi-itunes:before {
  content: "\F676";
}

.mdi-jeepney:before {
  content: "\F302";
}

.mdi-jira:before {
  content: "\F303";
}

.mdi-jsfiddle:before {
  content: "\F304";
}

.mdi-json:before {
  content: "\F626";
}

.mdi-keg:before {
  content: "\F305";
}

.mdi-kettle:before {
  content: "\F5FA";
}

.mdi-key:before {
  content: "\F306";
}

.mdi-key-change:before {
  content: "\F307";
}

.mdi-key-minus:before {
  content: "\F308";
}

.mdi-key-plus:before {
  content: "\F309";
}

.mdi-key-remove:before {
  content: "\F30A";
}

.mdi-key-variant:before {
  content: "\F30B";
}

.mdi-keyboard:before {
  content: "\F30C";
}

.mdi-keyboard-backspace:before {
  content: "\F30D";
}

.mdi-keyboard-caps:before {
  content: "\F30E";
}

.mdi-keyboard-close:before {
  content: "\F30F";
}

.mdi-keyboard-off:before {
  content: "\F310";
}

.mdi-keyboard-return:before {
  content: "\F311";
}

.mdi-keyboard-tab:before {
  content: "\F312";
}

.mdi-keyboard-variant:before {
  content: "\F313";
}

.mdi-kickstarter:before {
  content: "\F744";
}

.mdi-kodi:before {
  content: "\F314";
}

.mdi-label:before {
  content: "\F315";
}

.mdi-label-outline:before {
  content: "\F316";
}

.mdi-lambda:before {
  content: "\F627";
}

.mdi-lamp:before {
  content: "\F6B4";
}

.mdi-lan:before {
  content: "\F317";
}

.mdi-lan-connect:before {
  content: "\F318";
}

.mdi-lan-disconnect:before {
  content: "\F319";
}

.mdi-lan-pending:before {
  content: "\F31A";
}

.mdi-language-c:before {
  content: "\F671";
}

.mdi-language-cpp:before {
  content: "\F672";
}

.mdi-language-csharp:before {
  content: "\F31B";
}

.mdi-language-css3:before {
  content: "\F31C";
}

.mdi-language-go:before {
  content: "\F7D2";
}

.mdi-language-html5:before {
  content: "\F31D";
}

.mdi-language-javascript:before {
  content: "\F31E";
}

.mdi-language-php:before {
  content: "\F31F";
}

.mdi-language-python:before {
  content: "\F320";
}

.mdi-language-python-text:before {
  content: "\F321";
}

.mdi-language-r:before {
  content: "\F7D3";
}

.mdi-language-swift:before {
  content: "\F6E4";
}

.mdi-language-typescript:before {
  content: "\F6E5";
}

.mdi-laptop:before {
  content: "\F322";
}

.mdi-laptop-chromebook:before {
  content: "\F323";
}

.mdi-laptop-mac:before {
  content: "\F324";
}

.mdi-laptop-off:before {
  content: "\F6E6";
}

.mdi-laptop-windows:before {
  content: "\F325";
}

.mdi-lastfm:before {
  content: "\F326";
}

.mdi-launch:before {
  content: "\F327";
}

.mdi-lava-lamp:before {
  content: "\F7D4";
}

.mdi-layers:before {
  content: "\F328";
}

.mdi-layers-off:before {
  content: "\F329";
}

.mdi-lead-pencil:before {
  content: "\F64F";
}

.mdi-leaf:before {
  content: "\F32A";
}

.mdi-led-off:before {
  content: "\F32B";
}

.mdi-led-on:before {
  content: "\F32C";
}

.mdi-led-outline:before {
  content: "\F32D";
}

.mdi-led-strip:before {
  content: "\F7D5";
}

.mdi-led-variant-off:before {
  content: "\F32E";
}

.mdi-led-variant-on:before {
  content: "\F32F";
}

.mdi-led-variant-outline:before {
  content: "\F330";
}

.mdi-library:before {
  content: "\F331";
}

.mdi-library-books:before {
  content: "\F332";
}

.mdi-library-music:before {
  content: "\F333";
}

.mdi-library-plus:before {
  content: "\F334";
}

.mdi-lightbulb:before {
  content: "\F335";
}

.mdi-lightbulb-on:before {
  content: "\F6E7";
}

.mdi-lightbulb-on-outline:before {
  content: "\F6E8";
}

.mdi-lightbulb-outline:before {
  content: "\F336";
}

.mdi-link:before {
  content: "\F337";
}

.mdi-link-off:before {
  content: "\F338";
}

.mdi-link-variant:before {
  content: "\F339";
}

.mdi-link-variant-off:before {
  content: "\F33A";
}

.mdi-linkedin:before {
  content: "\F33B";
}

.mdi-linkedin-box:before {
  content: "\F33C";
}

.mdi-linux:before {
  content: "\F33D";
}

.mdi-loading:before {
  content: "\F771";
}

.mdi-lock:before {
  content: "\F33E";
}

.mdi-lock-open:before {
  content: "\F33F";
}

.mdi-lock-open-outline:before {
  content: "\F340";
}

.mdi-lock-outline:before {
  content: "\F341";
}

.mdi-lock-pattern:before {
  content: "\F6E9";
}

.mdi-lock-plus:before {
  content: "\F5FB";
}

.mdi-lock-reset:before {
  content: "\F772";
}

.mdi-locker:before {
  content: "\F7D6";
}

.mdi-locker-multiple:before {
  content: "\F7D7";
}

.mdi-login:before {
  content: "\F342";
}

.mdi-login-variant:before {
  content: "\F5FC";
}

.mdi-logout:before {
  content: "\F343";
}

.mdi-logout-variant:before {
  content: "\F5FD";
}

.mdi-looks:before {
  content: "\F344";
}

.mdi-loop:before {
  content: "\F6EA";
}

.mdi-loupe:before {
  content: "\F345";
}

.mdi-lumx:before {
  content: "\F346";
}

.mdi-magnet:before {
  content: "\F347";
}

.mdi-magnet-on:before {
  content: "\F348";
}

.mdi-magnify:before {
  content: "\F349";
}

.mdi-magnify-minus:before {
  content: "\F34A";
}

.mdi-magnify-minus-outline:before {
  content: "\F6EB";
}

.mdi-magnify-plus:before {
  content: "\F34B";
}

.mdi-magnify-plus-outline:before {
  content: "\F6EC";
}

.mdi-mail-ru:before {
  content: "\F34C";
}

.mdi-mailbox:before {
  content: "\F6ED";
}

.mdi-map:before {
  content: "\F34D";
}

.mdi-map-marker:before {
  content: "\F34E";
}

.mdi-map-marker-circle:before {
  content: "\F34F";
}

.mdi-map-marker-minus:before {
  content: "\F650";
}

.mdi-map-marker-multiple:before {
  content: "\F350";
}

.mdi-map-marker-off:before {
  content: "\F351";
}

.mdi-map-marker-outline:before {
  content: "\F7D8";
}

.mdi-map-marker-plus:before {
  content: "\F651";
}

.mdi-map-marker-radius:before {
  content: "\F352";
}

.mdi-margin:before {
  content: "\F353";
}

.mdi-markdown:before {
  content: "\F354";
}

.mdi-marker:before {
  content: "\F652";
}

.mdi-marker-check:before {
  content: "\F355";
}

.mdi-martini:before {
  content: "\F356";
}

.mdi-material-ui:before {
  content: "\F357";
}

.mdi-math-compass:before {
  content: "\F358";
}

.mdi-matrix:before {
  content: "\F628";
}

.mdi-maxcdn:before {
  content: "\F359";
}

.mdi-medical-bag:before {
  content: "\F6EE";
}

.mdi-medium:before {
  content: "\F35A";
}

.mdi-memory:before {
  content: "\F35B";
}

.mdi-menu:before {
  content: "\F35C";
}

.mdi-menu-down:before {
  content: "\F35D";
}

.mdi-menu-down-outline:before {
  content: "\F6B5";
}

.mdi-menu-left:before {
  content: "\F35E";
}

.mdi-menu-right:before {
  content: "\F35F";
}

.mdi-menu-up:before {
  content: "\F360";
}

.mdi-menu-up-outline:before {
  content: "\F6B6";
}

.mdi-message:before {
  content: "\F361";
}

.mdi-message-alert:before {
  content: "\F362";
}

.mdi-message-bulleted:before {
  content: "\F6A1";
}

.mdi-message-bulleted-off:before {
  content: "\F6A2";
}

.mdi-message-draw:before {
  content: "\F363";
}

.mdi-message-image:before {
  content: "\F364";
}

.mdi-message-outline:before {
  content: "\F365";
}

.mdi-message-plus:before {
  content: "\F653";
}

.mdi-message-processing:before {
  content: "\F366";
}

.mdi-message-reply:before {
  content: "\F367";
}

.mdi-message-reply-text:before {
  content: "\F368";
}

.mdi-message-settings:before {
  content: "\F6EF";
}

.mdi-message-settings-variant:before {
  content: "\F6F0";
}

.mdi-message-text:before {
  content: "\F369";
}

.mdi-message-text-outline:before {
  content: "\F36A";
}

.mdi-message-video:before {
  content: "\F36B";
}

.mdi-meteor:before {
  content: "\F629";
}

.mdi-metronome:before {
  content: "\F7D9";
}

.mdi-metronome-tick:before {
  content: "\F7DA";
}

.mdi-micro-sd:before {
  content: "\F7DB";
}

.mdi-microphone:before {
  content: "\F36C";
}

.mdi-microphone-off:before {
  content: "\F36D";
}

.mdi-microphone-outline:before {
  content: "\F36E";
}

.mdi-microphone-settings:before {
  content: "\F36F";
}

.mdi-microphone-variant:before {
  content: "\F370";
}

.mdi-microphone-variant-off:before {
  content: "\F371";
}

.mdi-microscope:before {
  content: "\F654";
}

.mdi-microsoft:before {
  content: "\F372";
}

.mdi-minecraft:before {
  content: "\F373";
}

.mdi-minus:before {
  content: "\F374";
}

.mdi-minus-box:before {
  content: "\F375";
}

.mdi-minus-box-outline:before {
  content: "\F6F1";
}

.mdi-minus-circle:before {
  content: "\F376";
}

.mdi-minus-circle-outline:before {
  content: "\F377";
}

.mdi-minus-network:before {
  content: "\F378";
}

.mdi-mixcloud:before {
  content: "\F62A";
}

.mdi-mixer:before {
  content: "\F7DC";
}

.mdi-monitor:before {
  content: "\F379";
}

.mdi-monitor-multiple:before {
  content: "\F37A";
}

.mdi-more:before {
  content: "\F37B";
}

.mdi-motorbike:before {
  content: "\F37C";
}

.mdi-mouse:before {
  content: "\F37D";
}

.mdi-mouse-off:before {
  content: "\F37E";
}

.mdi-mouse-variant:before {
  content: "\F37F";
}

.mdi-mouse-variant-off:before {
  content: "\F380";
}

.mdi-move-resize:before {
  content: "\F655";
}

.mdi-move-resize-variant:before {
  content: "\F656";
}

.mdi-movie:before {
  content: "\F381";
}

.mdi-movie-roll:before {
  content: "\F7DD";
}

.mdi-multiplication:before {
  content: "\F382";
}

.mdi-multiplication-box:before {
  content: "\F383";
}

.mdi-mushroom:before {
  content: "\F7DE";
}

.mdi-mushroom-outline:before {
  content: "\F7DF";
}

.mdi-music:before {
  content: "\F759";
}

.mdi-music-box:before {
  content: "\F384";
}

.mdi-music-box-outline:before {
  content: "\F385";
}

.mdi-music-circle:before {
  content: "\F386";
}

.mdi-music-note:before {
  content: "\F387";
}

.mdi-music-note-bluetooth:before {
  content: "\F5FE";
}

.mdi-music-note-bluetooth-off:before {
  content: "\F5FF";
}

.mdi-music-note-eighth:before {
  content: "\F388";
}

.mdi-music-note-half:before {
  content: "\F389";
}

.mdi-music-note-off:before {
  content: "\F38A";
}

.mdi-music-note-quarter:before {
  content: "\F38B";
}

.mdi-music-note-sixteenth:before {
  content: "\F38C";
}

.mdi-music-note-whole:before {
  content: "\F38D";
}

.mdi-music-off:before {
  content: "\F75A";
}

.mdi-nature:before {
  content: "\F38E";
}

.mdi-nature-people:before {
  content: "\F38F";
}

.mdi-navigation:before {
  content: "\F390";
}

.mdi-near-me:before {
  content: "\F5CD";
}

.mdi-needle:before {
  content: "\F391";
}

.mdi-nest-protect:before {
  content: "\F392";
}

.mdi-nest-thermostat:before {
  content: "\F393";
}

.mdi-netflix:before {
  content: "\F745";
}

.mdi-network:before {
  content: "\F6F2";
}

.mdi-new-box:before {
  content: "\F394";
}

.mdi-newspaper:before {
  content: "\F395";
}

.mdi-nfc:before {
  content: "\F396";
}

.mdi-nfc-tap:before {
  content: "\F397";
}

.mdi-nfc-variant:before {
  content: "\F398";
}

.mdi-ninja:before {
  content: "\F773";
}

.mdi-nintendo-switch:before {
  content: "\F7E0";
}

.mdi-nodejs:before {
  content: "\F399";
}

.mdi-note:before {
  content: "\F39A";
}

.mdi-note-multiple:before {
  content: "\F6B7";
}

.mdi-note-multiple-outline:before {
  content: "\F6B8";
}

.mdi-note-outline:before {
  content: "\F39B";
}

.mdi-note-plus:before {
  content: "\F39C";
}

.mdi-note-plus-outline:before {
  content: "\F39D";
}

.mdi-note-text:before {
  content: "\F39E";
}

.mdi-notification-clear-all:before {
  content: "\F39F";
}

.mdi-npm:before {
  content: "\F6F6";
}

.mdi-nuke:before {
  content: "\F6A3";
}

.mdi-null:before {
  content: "\F7E1";
}

.mdi-numeric:before {
  content: "\F3A0";
}

.mdi-numeric-0-box:before {
  content: "\F3A1";
}

.mdi-numeric-0-box-multiple-outline:before {
  content: "\F3A2";
}

.mdi-numeric-0-box-outline:before {
  content: "\F3A3";
}

.mdi-numeric-1-box:before {
  content: "\F3A4";
}

.mdi-numeric-1-box-multiple-outline:before {
  content: "\F3A5";
}

.mdi-numeric-1-box-outline:before {
  content: "\F3A6";
}

.mdi-numeric-2-box:before {
  content: "\F3A7";
}

.mdi-numeric-2-box-multiple-outline:before {
  content: "\F3A8";
}

.mdi-numeric-2-box-outline:before {
  content: "\F3A9";
}

.mdi-numeric-3-box:before {
  content: "\F3AA";
}

.mdi-numeric-3-box-multiple-outline:before {
  content: "\F3AB";
}

.mdi-numeric-3-box-outline:before {
  content: "\F3AC";
}

.mdi-numeric-4-box:before {
  content: "\F3AD";
}

.mdi-numeric-4-box-multiple-outline:before {
  content: "\F3AE";
}

.mdi-numeric-4-box-outline:before {
  content: "\F3AF";
}

.mdi-numeric-5-box:before {
  content: "\F3B0";
}

.mdi-numeric-5-box-multiple-outline:before {
  content: "\F3B1";
}

.mdi-numeric-5-box-outline:before {
  content: "\F3B2";
}

.mdi-numeric-6-box:before {
  content: "\F3B3";
}

.mdi-numeric-6-box-multiple-outline:before {
  content: "\F3B4";
}

.mdi-numeric-6-box-outline:before {
  content: "\F3B5";
}

.mdi-numeric-7-box:before {
  content: "\F3B6";
}

.mdi-numeric-7-box-multiple-outline:before {
  content: "\F3B7";
}

.mdi-numeric-7-box-outline:before {
  content: "\F3B8";
}

.mdi-numeric-8-box:before {
  content: "\F3B9";
}

.mdi-numeric-8-box-multiple-outline:before {
  content: "\F3BA";
}

.mdi-numeric-8-box-outline:before {
  content: "\F3BB";
}

.mdi-numeric-9-box:before {
  content: "\F3BC";
}

.mdi-numeric-9-box-multiple-outline:before {
  content: "\F3BD";
}

.mdi-numeric-9-box-outline:before {
  content: "\F3BE";
}

.mdi-numeric-9-plus-box:before {
  content: "\F3BF";
}

.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "\F3C0";
}

.mdi-numeric-9-plus-box-outline:before {
  content: "\F3C1";
}

.mdi-nut:before {
  content: "\F6F7";
}

.mdi-nutrition:before {
  content: "\F3C2";
}

.mdi-oar:before {
  content: "\F67B";
}

.mdi-octagon:before {
  content: "\F3C3";
}

.mdi-octagon-outline:before {
  content: "\F3C4";
}

.mdi-octagram:before {
  content: "\F6F8";
}

.mdi-octagram-outline:before {
  content: "\F774";
}

.mdi-odnoklassniki:before {
  content: "\F3C5";
}

.mdi-office:before {
  content: "\F3C6";
}

.mdi-oil:before {
  content: "\F3C7";
}

.mdi-oil-temperature:before {
  content: "\F3C8";
}

.mdi-omega:before {
  content: "\F3C9";
}

.mdi-onedrive:before {
  content: "\F3CA";
}

.mdi-onenote:before {
  content: "\F746";
}

.mdi-opacity:before {
  content: "\F5CC";
}

.mdi-open-in-app:before {
  content: "\F3CB";
}

.mdi-open-in-new:before {
  content: "\F3CC";
}

.mdi-openid:before {
  content: "\F3CD";
}

.mdi-opera:before {
  content: "\F3CE";
}

.mdi-orbit:before {
  content: "\F018";
}

.mdi-ornament:before {
  content: "\F3CF";
}

.mdi-ornament-variant:before {
  content: "\F3D0";
}

.mdi-owl:before {
  content: "\F3D2";
}

.mdi-package:before {
  content: "\F3D3";
}

.mdi-package-down:before {
  content: "\F3D4";
}

.mdi-package-up:before {
  content: "\F3D5";
}

.mdi-package-variant:before {
  content: "\F3D6";
}

.mdi-package-variant-closed:before {
  content: "\F3D7";
}

.mdi-page-first:before {
  content: "\F600";
}

.mdi-page-last:before {
  content: "\F601";
}

.mdi-page-layout-body:before {
  content: "\F6F9";
}

.mdi-page-layout-footer:before {
  content: "\F6FA";
}

.mdi-page-layout-header:before {
  content: "\F6FB";
}

.mdi-page-layout-sidebar-left:before {
  content: "\F6FC";
}

.mdi-page-layout-sidebar-right:before {
  content: "\F6FD";
}

.mdi-palette:before {
  content: "\F3D8";
}

.mdi-palette-advanced:before {
  content: "\F3D9";
}

.mdi-panda:before {
  content: "\F3DA";
}

.mdi-pandora:before {
  content: "\F3DB";
}

.mdi-panorama:before {
  content: "\F3DC";
}

.mdi-panorama-fisheye:before {
  content: "\F3DD";
}

.mdi-panorama-horizontal:before {
  content: "\F3DE";
}

.mdi-panorama-vertical:before {
  content: "\F3DF";
}

.mdi-panorama-wide-angle:before {
  content: "\F3E0";
}

.mdi-paper-cut-vertical:before {
  content: "\F3E1";
}

.mdi-paperclip:before {
  content: "\F3E2";
}

.mdi-parking:before {
  content: "\F3E3";
}

.mdi-passport:before {
  content: "\F7E2";
}

.mdi-pause:before {
  content: "\F3E4";
}

.mdi-pause-circle:before {
  content: "\F3E5";
}

.mdi-pause-circle-outline:before {
  content: "\F3E6";
}

.mdi-pause-octagon:before {
  content: "\F3E7";
}

.mdi-pause-octagon-outline:before {
  content: "\F3E8";
}

.mdi-paw:before {
  content: "\F3E9";
}

.mdi-paw-off:before {
  content: "\F657";
}

.mdi-pen:before {
  content: "\F3EA";
}

.mdi-pencil:before {
  content: "\F3EB";
}

.mdi-pencil-box:before {
  content: "\F3EC";
}

.mdi-pencil-box-outline:before {
  content: "\F3ED";
}

.mdi-pencil-circle:before {
  content: "\F6FE";
}

.mdi-pencil-circle-outline:before {
  content: "\F775";
}

.mdi-pencil-lock:before {
  content: "\F3EE";
}

.mdi-pencil-off:before {
  content: "\F3EF";
}

.mdi-pentagon:before {
  content: "\F6FF";
}

.mdi-pentagon-outline:before {
  content: "\F700";
}

.mdi-percent:before {
  content: "\F3F0";
}

.mdi-periodic-table-co2:before {
  content: "\F7E3";
}

.mdi-periscope:before {
  content: "\F747";
}

.mdi-pharmacy:before {
  content: "\F3F1";
}

.mdi-phone:before {
  content: "\F3F2";
}

.mdi-phone-bluetooth:before {
  content: "\F3F3";
}

.mdi-phone-classic:before {
  content: "\F602";
}

.mdi-phone-forward:before {
  content: "\F3F4";
}

.mdi-phone-hangup:before {
  content: "\F3F5";
}

.mdi-phone-in-talk:before {
  content: "\F3F6";
}

.mdi-phone-incoming:before {
  content: "\F3F7";
}

.mdi-phone-locked:before {
  content: "\F3F8";
}

.mdi-phone-log:before {
  content: "\F3F9";
}

.mdi-phone-minus:before {
  content: "\F658";
}

.mdi-phone-missed:before {
  content: "\F3FA";
}

.mdi-phone-outgoing:before {
  content: "\F3FB";
}

.mdi-phone-paused:before {
  content: "\F3FC";
}

.mdi-phone-plus:before {
  content: "\F659";
}

.mdi-phone-settings:before {
  content: "\F3FD";
}

.mdi-phone-voip:before {
  content: "\F3FE";
}

.mdi-pi:before {
  content: "\F3FF";
}

.mdi-pi-box:before {
  content: "\F400";
}

.mdi-piano:before {
  content: "\F67C";
}

.mdi-pig:before {
  content: "\F401";
}

.mdi-pill:before {
  content: "\F402";
}

.mdi-pillar:before {
  content: "\F701";
}

.mdi-pin:before {
  content: "\F403";
}

.mdi-pin-off:before {
  content: "\F404";
}

.mdi-pine-tree:before {
  content: "\F405";
}

.mdi-pine-tree-box:before {
  content: "\F406";
}

.mdi-pinterest:before {
  content: "\F407";
}

.mdi-pinterest-box:before {
  content: "\F408";
}

.mdi-pipe:before {
  content: "\F7E4";
}

.mdi-pipe-disconnected:before {
  content: "\F7E5";
}

.mdi-pistol:before {
  content: "\F702";
}

.mdi-pizza:before {
  content: "\F409";
}

.mdi-plane-shield:before {
  content: "\F6BA";
}

.mdi-play:before {
  content: "\F40A";
}

.mdi-play-box-outline:before {
  content: "\F40B";
}

.mdi-play-circle:before {
  content: "\F40C";
}

.mdi-play-circle-outline:before {
  content: "\F40D";
}

.mdi-play-pause:before {
  content: "\F40E";
}

.mdi-play-protected-content:before {
  content: "\F40F";
}

.mdi-playlist-check:before {
  content: "\F5C7";
}

.mdi-playlist-minus:before {
  content: "\F410";
}

.mdi-playlist-play:before {
  content: "\F411";
}

.mdi-playlist-plus:before {
  content: "\F412";
}

.mdi-playlist-remove:before {
  content: "\F413";
}

.mdi-playstation:before {
  content: "\F414";
}

.mdi-plex:before {
  content: "\F6B9";
}

.mdi-plus:before {
  content: "\F415";
}

.mdi-plus-box:before {
  content: "\F416";
}

.mdi-plus-box-outline:before {
  content: "\F703";
}

.mdi-plus-circle:before {
  content: "\F417";
}

.mdi-plus-circle-multiple-outline:before {
  content: "\F418";
}

.mdi-plus-circle-outline:before {
  content: "\F419";
}

.mdi-plus-network:before {
  content: "\F41A";
}

.mdi-plus-one:before {
  content: "\F41B";
}

.mdi-plus-outline:before {
  content: "\F704";
}

.mdi-pocket:before {
  content: "\F41C";
}

.mdi-pokeball:before {
  content: "\F41D";
}

.mdi-polaroid:before {
  content: "\F41E";
}

.mdi-poll:before {
  content: "\F41F";
}

.mdi-poll-box:before {
  content: "\F420";
}

.mdi-polymer:before {
  content: "\F421";
}

.mdi-pool:before {
  content: "\F606";
}

.mdi-popcorn:before {
  content: "\F422";
}

.mdi-pot:before {
  content: "\F65A";
}

.mdi-pot-mix:before {
  content: "\F65B";
}

.mdi-pound:before {
  content: "\F423";
}

.mdi-pound-box:before {
  content: "\F424";
}

.mdi-power:before {
  content: "\F425";
}

.mdi-power-plug:before {
  content: "\F6A4";
}

.mdi-power-plug-off:before {
  content: "\F6A5";
}

.mdi-power-settings:before {
  content: "\F426";
}

.mdi-power-socket:before {
  content: "\F427";
}

.mdi-power-socket-eu:before {
  content: "\F7E6";
}

.mdi-power-socket-uk:before {
  content: "\F7E7";
}

.mdi-power-socket-us:before {
  content: "\F7E8";
}

.mdi-prescription:before {
  content: "\F705";
}

.mdi-presentation:before {
  content: "\F428";
}

.mdi-presentation-play:before {
  content: "\F429";
}

.mdi-printer:before {
  content: "\F42A";
}

.mdi-printer-3d:before {
  content: "\F42B";
}

.mdi-printer-alert:before {
  content: "\F42C";
}

.mdi-printer-settings:before {
  content: "\F706";
}

.mdi-priority-high:before {
  content: "\F603";
}

.mdi-priority-low:before {
  content: "\F604";
}

.mdi-professional-hexagon:before {
  content: "\F42D";
}

.mdi-projector:before {
  content: "\F42E";
}

.mdi-projector-screen:before {
  content: "\F42F";
}

.mdi-publish:before {
  content: "\F6A6";
}

.mdi-pulse:before {
  content: "\F430";
}

.mdi-puzzle:before {
  content: "\F431";
}

.mdi-qqchat:before {
  content: "\F605";
}

.mdi-qrcode:before {
  content: "\F432";
}

.mdi-qrcode-scan:before {
  content: "\F433";
}

.mdi-quadcopter:before {
  content: "\F434";
}

.mdi-quality-high:before {
  content: "\F435";
}

.mdi-quicktime:before {
  content: "\F436";
}

.mdi-radar:before {
  content: "\F437";
}

.mdi-radiator:before {
  content: "\F438";
}

.mdi-radio:before {
  content: "\F439";
}

.mdi-radio-handheld:before {
  content: "\F43A";
}

.mdi-radio-tower:before {
  content: "\F43B";
}

.mdi-radioactive:before {
  content: "\F43C";
}

.mdi-radiobox-blank:before {
  content: "\F43D";
}

.mdi-radiobox-marked:before {
  content: "\F43E";
}

.mdi-raspberrypi:before {
  content: "\F43F";
}

.mdi-ray-end:before {
  content: "\F440";
}

.mdi-ray-end-arrow:before {
  content: "\F441";
}

.mdi-ray-start:before {
  content: "\F442";
}

.mdi-ray-start-arrow:before {
  content: "\F443";
}

.mdi-ray-start-end:before {
  content: "\F444";
}

.mdi-ray-vertex:before {
  content: "\F445";
}

.mdi-rdio:before {
  content: "\F446";
}

.mdi-react:before {
  content: "\F707";
}

.mdi-read:before {
  content: "\F447";
}

.mdi-readability:before {
  content: "\F448";
}

.mdi-receipt:before {
  content: "\F449";
}

.mdi-record:before {
  content: "\F44A";
}

.mdi-record-rec:before {
  content: "\F44B";
}

.mdi-recycle:before {
  content: "\F44C";
}

.mdi-reddit:before {
  content: "\F44D";
}

.mdi-redo:before {
  content: "\F44E";
}

.mdi-redo-variant:before {
  content: "\F44F";
}

.mdi-refresh:before {
  content: "\F450";
}

.mdi-regex:before {
  content: "\F451";
}

.mdi-relative-scale:before {
  content: "\F452";
}

.mdi-reload:before {
  content: "\F453";
}

.mdi-remote:before {
  content: "\F454";
}

.mdi-rename-box:before {
  content: "\F455";
}

.mdi-reorder-horizontal:before {
  content: "\F687";
}

.mdi-reorder-vertical:before {
  content: "\F688";
}

.mdi-repeat:before {
  content: "\F456";
}

.mdi-repeat-off:before {
  content: "\F457";
}

.mdi-repeat-once:before {
  content: "\F458";
}

.mdi-replay:before {
  content: "\F459";
}

.mdi-reply:before {
  content: "\F45A";
}

.mdi-reply-all:before {
  content: "\F45B";
}

.mdi-reproduction:before {
  content: "\F45C";
}

.mdi-resize-bottom-right:before {
  content: "\F45D";
}

.mdi-responsive:before {
  content: "\F45E";
}

.mdi-restart:before {
  content: "\F708";
}

.mdi-restore:before {
  content: "\F6A7";
}

.mdi-rewind:before {
  content: "\F45F";
}

.mdi-rewind-outline:before {
  content: "\F709";
}

.mdi-rhombus:before {
  content: "\F70A";
}

.mdi-rhombus-outline:before {
  content: "\F70B";
}

.mdi-ribbon:before {
  content: "\F460";
}

.mdi-rice:before {
  content: "\F7E9";
}

.mdi-ring:before {
  content: "\F7EA";
}

.mdi-road:before {
  content: "\F461";
}

.mdi-road-variant:before {
  content: "\F462";
}

.mdi-robot:before {
  content: "\F6A8";
}

.mdi-rocket:before {
  content: "\F463";
}

.mdi-roomba:before {
  content: "\F70C";
}

.mdi-rotate-3d:before {
  content: "\F464";
}

.mdi-rotate-left:before {
  content: "\F465";
}

.mdi-rotate-left-variant:before {
  content: "\F466";
}

.mdi-rotate-right:before {
  content: "\F467";
}

.mdi-rotate-right-variant:before {
  content: "\F468";
}

.mdi-rounded-corner:before {
  content: "\F607";
}

.mdi-router-wireless:before {
  content: "\F469";
}

.mdi-routes:before {
  content: "\F46A";
}

.mdi-rowing:before {
  content: "\F608";
}

.mdi-rss:before {
  content: "\F46B";
}

.mdi-rss-box:before {
  content: "\F46C";
}

.mdi-ruler:before {
  content: "\F46D";
}

.mdi-run:before {
  content: "\F70D";
}

.mdi-run-fast:before {
  content: "\F46E";
}

.mdi-sale:before {
  content: "\F46F";
}

.mdi-sass:before {
  content: "\F7EB";
}

.mdi-satellite:before {
  content: "\F470";
}

.mdi-satellite-variant:before {
  content: "\F471";
}

.mdi-saxophone:before {
  content: "\F609";
}

.mdi-scale:before {
  content: "\F472";
}

.mdi-scale-balance:before {
  content: "\F5D1";
}

.mdi-scale-bathroom:before {
  content: "\F473";
}

.mdi-scanner:before {
  content: "\F6AA";
}

.mdi-school:before {
  content: "\F474";
}

.mdi-screen-rotation:before {
  content: "\F475";
}

.mdi-screen-rotation-lock:before {
  content: "\F476";
}

.mdi-screwdriver:before {
  content: "\F477";
}

.mdi-script:before {
  content: "\F478";
}

.mdi-sd:before {
  content: "\F479";
}

.mdi-seal:before {
  content: "\F47A";
}

.mdi-search-web:before {
  content: "\F70E";
}

.mdi-seat-flat:before {
  content: "\F47B";
}

.mdi-seat-flat-angled:before {
  content: "\F47C";
}

.mdi-seat-individual-suite:before {
  content: "\F47D";
}

.mdi-seat-legroom-extra:before {
  content: "\F47E";
}

.mdi-seat-legroom-normal:before {
  content: "\F47F";
}

.mdi-seat-legroom-reduced:before {
  content: "\F480";
}

.mdi-seat-recline-extra:before {
  content: "\F481";
}

.mdi-seat-recline-normal:before {
  content: "\F482";
}

.mdi-security:before {
  content: "\F483";
}

.mdi-security-home:before {
  content: "\F689";
}

.mdi-security-network:before {
  content: "\F484";
}

.mdi-select:before {
  content: "\F485";
}

.mdi-select-all:before {
  content: "\F486";
}

.mdi-select-inverse:before {
  content: "\F487";
}

.mdi-select-off:before {
  content: "\F488";
}

.mdi-selection:before {
  content: "\F489";
}

.mdi-selection-off:before {
  content: "\F776";
}

.mdi-send:before {
  content: "\F48A";
}

.mdi-send-secure:before {
  content: "\F7EC";
}

.mdi-serial-port:before {
  content: "\F65C";
}

.mdi-server:before {
  content: "\F48B";
}

.mdi-server-minus:before {
  content: "\F48C";
}

.mdi-server-network:before {
  content: "\F48D";
}

.mdi-server-network-off:before {
  content: "\F48E";
}

.mdi-server-off:before {
  content: "\F48F";
}

.mdi-server-plus:before {
  content: "\F490";
}

.mdi-server-remove:before {
  content: "\F491";
}

.mdi-server-security:before {
  content: "\F492";
}

.mdi-set-all:before {
  content: "\F777";
}

.mdi-set-center:before {
  content: "\F778";
}

.mdi-set-center-right:before {
  content: "\F779";
}

.mdi-set-left:before {
  content: "\F77A";
}

.mdi-set-left-center:before {
  content: "\F77B";
}

.mdi-set-left-right:before {
  content: "\F77C";
}

.mdi-set-none:before {
  content: "\F77D";
}

.mdi-set-right:before {
  content: "\F77E";
}

.mdi-settings:before {
  content: "\F493";
}

.mdi-settings-box:before {
  content: "\F494";
}

.mdi-shape-circle-plus:before {
  content: "\F65D";
}

.mdi-shape-plus:before {
  content: "\F495";
}

.mdi-shape-polygon-plus:before {
  content: "\F65E";
}

.mdi-shape-rectangle-plus:before {
  content: "\F65F";
}

.mdi-shape-square-plus:before {
  content: "\F660";
}

.mdi-share:before {
  content: "\F496";
}

.mdi-share-variant:before {
  content: "\F497";
}

.mdi-shield:before {
  content: "\F498";
}

.mdi-shield-half-full:before {
  content: "\F77F";
}

.mdi-shield-outline:before {
  content: "\F499";
}

.mdi-shopping:before {
  content: "\F49A";
}

.mdi-shopping-music:before {
  content: "\F49B";
}

.mdi-shovel:before {
  content: "\F70F";
}

.mdi-shovel-off:before {
  content: "\F710";
}

.mdi-shredder:before {
  content: "\F49C";
}

.mdi-shuffle:before {
  content: "\F49D";
}

.mdi-shuffle-disabled:before {
  content: "\F49E";
}

.mdi-shuffle-variant:before {
  content: "\F49F";
}

.mdi-sigma:before {
  content: "\F4A0";
}

.mdi-sigma-lower:before {
  content: "\F62B";
}

.mdi-sign-caution:before {
  content: "\F4A1";
}

.mdi-sign-direction:before {
  content: "\F780";
}

.mdi-sign-text:before {
  content: "\F781";
}

.mdi-signal:before {
  content: "\F4A2";
}

.mdi-signal-2g:before {
  content: "\F711";
}

.mdi-signal-3g:before {
  content: "\F712";
}

.mdi-signal-4g:before {
  content: "\F713";
}

.mdi-signal-hspa:before {
  content: "\F714";
}

.mdi-signal-hspa-plus:before {
  content: "\F715";
}

.mdi-signal-off:before {
  content: "\F782";
}

.mdi-signal-variant:before {
  content: "\F60A";
}

.mdi-silverware:before {
  content: "\F4A3";
}

.mdi-silverware-fork:before {
  content: "\F4A4";
}

.mdi-silverware-spoon:before {
  content: "\F4A5";
}

.mdi-silverware-variant:before {
  content: "\F4A6";
}

.mdi-sim:before {
  content: "\F4A7";
}

.mdi-sim-alert:before {
  content: "\F4A8";
}

.mdi-sim-off:before {
  content: "\F4A9";
}

.mdi-sitemap:before {
  content: "\F4AA";
}

.mdi-skip-backward:before {
  content: "\F4AB";
}

.mdi-skip-forward:before {
  content: "\F4AC";
}

.mdi-skip-next:before {
  content: "\F4AD";
}

.mdi-skip-next-circle:before {
  content: "\F661";
}

.mdi-skip-next-circle-outline:before {
  content: "\F662";
}

.mdi-skip-previous:before {
  content: "\F4AE";
}

.mdi-skip-previous-circle:before {
  content: "\F663";
}

.mdi-skip-previous-circle-outline:before {
  content: "\F664";
}

.mdi-skull:before {
  content: "\F68B";
}

.mdi-skype:before {
  content: "\F4AF";
}

.mdi-skype-business:before {
  content: "\F4B0";
}

.mdi-slack:before {
  content: "\F4B1";
}

.mdi-sleep:before {
  content: "\F4B2";
}

.mdi-sleep-off:before {
  content: "\F4B3";
}

.mdi-smoking:before {
  content: "\F4B4";
}

.mdi-smoking-off:before {
  content: "\F4B5";
}

.mdi-snapchat:before {
  content: "\F4B6";
}

.mdi-snowflake:before {
  content: "\F716";
}

.mdi-snowman:before {
  content: "\F4B7";
}

.mdi-soccer:before {
  content: "\F4B8";
}

.mdi-sofa:before {
  content: "\F4B9";
}

.mdi-solid:before {
  content: "\F68C";
}

.mdi-sort:before {
  content: "\F4BA";
}

.mdi-sort-alphabetical:before {
  content: "\F4BB";
}

.mdi-sort-ascending:before {
  content: "\F4BC";
}

.mdi-sort-descending:before {
  content: "\F4BD";
}

.mdi-sort-numeric:before {
  content: "\F4BE";
}

.mdi-sort-variant:before {
  content: "\F4BF";
}

.mdi-soundcloud:before {
  content: "\F4C0";
}

.mdi-source-branch:before {
  content: "\F62C";
}

.mdi-source-commit:before {
  content: "\F717";
}

.mdi-source-commit-end:before {
  content: "\F718";
}

.mdi-source-commit-end-local:before {
  content: "\F719";
}

.mdi-source-commit-local:before {
  content: "\F71A";
}

.mdi-source-commit-next-local:before {
  content: "\F71B";
}

.mdi-source-commit-start:before {
  content: "\F71C";
}

.mdi-source-commit-start-next-local:before {
  content: "\F71D";
}

.mdi-source-fork:before {
  content: "\F4C1";
}

.mdi-source-merge:before {
  content: "\F62D";
}

.mdi-source-pull:before {
  content: "\F4C2";
}

.mdi-soy-sauce:before {
  content: "\F7ED";
}

.mdi-speaker:before {
  content: "\F4C3";
}

.mdi-speaker-off:before {
  content: "\F4C4";
}

.mdi-speaker-wireless:before {
  content: "\F71E";
}

.mdi-speedometer:before {
  content: "\F4C5";
}

.mdi-spellcheck:before {
  content: "\F4C6";
}

.mdi-spotify:before {
  content: "\F4C7";
}

.mdi-spotlight:before {
  content: "\F4C8";
}

.mdi-spotlight-beam:before {
  content: "\F4C9";
}

.mdi-spray:before {
  content: "\F665";
}

.mdi-square:before {
  content: "\F763";
}

.mdi-square-inc:before {
  content: "\F4CA";
}

.mdi-square-inc-cash:before {
  content: "\F4CB";
}

.mdi-square-outline:before {
  content: "\F762";
}

.mdi-square-root:before {
  content: "\F783";
}

.mdi-stackexchange:before {
  content: "\F60B";
}

.mdi-stackoverflow:before {
  content: "\F4CC";
}

.mdi-stadium:before {
  content: "\F71F";
}

.mdi-stairs:before {
  content: "\F4CD";
}

.mdi-standard-definition:before {
  content: "\F7EE";
}

.mdi-star:before {
  content: "\F4CE";
}

.mdi-star-circle:before {
  content: "\F4CF";
}

.mdi-star-half:before {
  content: "\F4D0";
}

.mdi-star-off:before {
  content: "\F4D1";
}

.mdi-star-outline:before {
  content: "\F4D2";
}

.mdi-steam:before {
  content: "\F4D3";
}

.mdi-steering:before {
  content: "\F4D4";
}

.mdi-step-backward:before {
  content: "\F4D5";
}

.mdi-step-backward-2:before {
  content: "\F4D6";
}

.mdi-step-forward:before {
  content: "\F4D7";
}

.mdi-step-forward-2:before {
  content: "\F4D8";
}

.mdi-stethoscope:before {
  content: "\F4D9";
}

.mdi-sticker:before {
  content: "\F5D0";
}

.mdi-sticker-emoji:before {
  content: "\F784";
}

.mdi-stocking:before {
  content: "\F4DA";
}

.mdi-stop:before {
  content: "\F4DB";
}

.mdi-stop-circle:before {
  content: "\F666";
}

.mdi-stop-circle-outline:before {
  content: "\F667";
}

.mdi-store:before {
  content: "\F4DC";
}

.mdi-store-24-hour:before {
  content: "\F4DD";
}

.mdi-stove:before {
  content: "\F4DE";
}

.mdi-subdirectory-arrow-left:before {
  content: "\F60C";
}

.mdi-subdirectory-arrow-right:before {
  content: "\F60D";
}

.mdi-subway:before {
  content: "\F6AB";
}

.mdi-subway-variant:before {
  content: "\F4DF";
}

.mdi-summit:before {
  content: "\F785";
}

.mdi-sunglasses:before {
  content: "\F4E0";
}

.mdi-surround-sound:before {
  content: "\F5C5";
}

.mdi-surround-sound-2-0:before {
  content: "\F7EF";
}

.mdi-surround-sound-3-1:before {
  content: "\F7F0";
}

.mdi-surround-sound-5-1:before {
  content: "\F7F1";
}

.mdi-surround-sound-7-1:before {
  content: "\F7F2";
}

.mdi-svg:before {
  content: "\F720";
}

.mdi-swap-horizontal:before {
  content: "\F4E1";
}

.mdi-swap-vertical:before {
  content: "\F4E2";
}

.mdi-swim:before {
  content: "\F4E3";
}

.mdi-switch:before {
  content: "\F4E4";
}

.mdi-sword:before {
  content: "\F4E5";
}

.mdi-sword-cross:before {
  content: "\F786";
}

.mdi-sync:before {
  content: "\F4E6";
}

.mdi-sync-alert:before {
  content: "\F4E7";
}

.mdi-sync-off:before {
  content: "\F4E8";
}

.mdi-tab:before {
  content: "\F4E9";
}

.mdi-tab-plus:before {
  content: "\F75B";
}

.mdi-tab-unselected:before {
  content: "\F4EA";
}

.mdi-table:before {
  content: "\F4EB";
}

.mdi-table-column-plus-after:before {
  content: "\F4EC";
}

.mdi-table-column-plus-before:before {
  content: "\F4ED";
}

.mdi-table-column-remove:before {
  content: "\F4EE";
}

.mdi-table-column-width:before {
  content: "\F4EF";
}

.mdi-table-edit:before {
  content: "\F4F0";
}

.mdi-table-large:before {
  content: "\F4F1";
}

.mdi-table-row-height:before {
  content: "\F4F2";
}

.mdi-table-row-plus-after:before {
  content: "\F4F3";
}

.mdi-table-row-plus-before:before {
  content: "\F4F4";
}

.mdi-table-row-remove:before {
  content: "\F4F5";
}

.mdi-tablet:before {
  content: "\F4F6";
}

.mdi-tablet-android:before {
  content: "\F4F7";
}

.mdi-tablet-ipad:before {
  content: "\F4F8";
}

.mdi-taco:before {
  content: "\F761";
}

.mdi-tag:before {
  content: "\F4F9";
}

.mdi-tag-faces:before {
  content: "\F4FA";
}

.mdi-tag-heart:before {
  content: "\F68A";
}

.mdi-tag-multiple:before {
  content: "\F4FB";
}

.mdi-tag-outline:before {
  content: "\F4FC";
}

.mdi-tag-plus:before {
  content: "\F721";
}

.mdi-tag-remove:before {
  content: "\F722";
}

.mdi-tag-text-outline:before {
  content: "\F4FD";
}

.mdi-target:before {
  content: "\F4FE";
}

.mdi-taxi:before {
  content: "\F4FF";
}

.mdi-teamviewer:before {
  content: "\F500";
}

.mdi-telegram:before {
  content: "\F501";
}

.mdi-television:before {
  content: "\F502";
}

.mdi-television-classic:before {
  content: "\F7F3";
}

.mdi-television-guide:before {
  content: "\F503";
}

.mdi-temperature-celsius:before {
  content: "\F504";
}

.mdi-temperature-fahrenheit:before {
  content: "\F505";
}

.mdi-temperature-kelvin:before {
  content: "\F506";
}

.mdi-tennis:before {
  content: "\F507";
}

.mdi-tent:before {
  content: "\F508";
}

.mdi-terrain:before {
  content: "\F509";
}

.mdi-test-tube:before {
  content: "\F668";
}

.mdi-text-shadow:before {
  content: "\F669";
}

.mdi-text-to-speech:before {
  content: "\F50A";
}

.mdi-text-to-speech-off:before {
  content: "\F50B";
}

.mdi-textbox:before {
  content: "\F60E";
}

.mdi-textbox-password:before {
  content: "\F7F4";
}

.mdi-texture:before {
  content: "\F50C";
}

.mdi-theater:before {
  content: "\F50D";
}

.mdi-theme-light-dark:before {
  content: "\F50E";
}

.mdi-thermometer:before {
  content: "\F50F";
}

.mdi-thermometer-lines:before {
  content: "\F510";
}

.mdi-thought-bubble:before {
  content: "\F7F5";
}

.mdi-thought-bubble-outline:before {
  content: "\F7F6";
}

.mdi-thumb-down:before {
  content: "\F511";
}

.mdi-thumb-down-outline:before {
  content: "\F512";
}

.mdi-thumb-up:before {
  content: "\F513";
}

.mdi-thumb-up-outline:before {
  content: "\F514";
}

.mdi-thumbs-up-down:before {
  content: "\F515";
}

.mdi-ticket:before {
  content: "\F516";
}

.mdi-ticket-account:before {
  content: "\F517";
}

.mdi-ticket-confirmation:before {
  content: "\F518";
}

.mdi-ticket-percent:before {
  content: "\F723";
}

.mdi-tie:before {
  content: "\F519";
}

.mdi-tilde:before {
  content: "\F724";
}

.mdi-timelapse:before {
  content: "\F51A";
}

.mdi-timer:before {
  content: "\F51B";
}

.mdi-timer-10:before {
  content: "\F51C";
}

.mdi-timer-3:before {
  content: "\F51D";
}

.mdi-timer-off:before {
  content: "\F51E";
}

.mdi-timer-sand:before {
  content: "\F51F";
}

.mdi-timer-sand-empty:before {
  content: "\F6AC";
}

.mdi-timer-sand-full:before {
  content: "\F78B";
}

.mdi-timetable:before {
  content: "\F520";
}

.mdi-toggle-switch:before {
  content: "\F521";
}

.mdi-toggle-switch-off:before {
  content: "\F522";
}

.mdi-tooltip:before {
  content: "\F523";
}

.mdi-tooltip-edit:before {
  content: "\F524";
}

.mdi-tooltip-image:before {
  content: "\F525";
}

.mdi-tooltip-outline:before {
  content: "\F526";
}

.mdi-tooltip-outline-plus:before {
  content: "\F527";
}

.mdi-tooltip-text:before {
  content: "\F528";
}

.mdi-tooth:before {
  content: "\F529";
}

.mdi-tor:before {
  content: "\F52A";
}

.mdi-tower-beach:before {
  content: "\F680";
}

.mdi-tower-fire:before {
  content: "\F681";
}

.mdi-trackpad:before {
  content: "\F7F7";
}

.mdi-traffic-light:before {
  content: "\F52B";
}

.mdi-train:before {
  content: "\F52C";
}

.mdi-tram:before {
  content: "\F52D";
}

.mdi-transcribe:before {
  content: "\F52E";
}

.mdi-transcribe-close:before {
  content: "\F52F";
}

.mdi-transfer:before {
  content: "\F530";
}

.mdi-transit-transfer:before {
  content: "\F6AD";
}

.mdi-translate:before {
  content: "\F5CA";
}

.mdi-treasure-chest:before {
  content: "\F725";
}

.mdi-tree:before {
  content: "\F531";
}

.mdi-trello:before {
  content: "\F532";
}

.mdi-trending-down:before {
  content: "\F533";
}

.mdi-trending-neutral:before {
  content: "\F534";
}

.mdi-trending-up:before {
  content: "\F535";
}

.mdi-triangle:before {
  content: "\F536";
}

.mdi-triangle-outline:before {
  content: "\F537";
}

.mdi-trophy:before {
  content: "\F538";
}

.mdi-trophy-award:before {
  content: "\F539";
}

.mdi-trophy-outline:before {
  content: "\F53A";
}

.mdi-trophy-variant:before {
  content: "\F53B";
}

.mdi-trophy-variant-outline:before {
  content: "\F53C";
}

.mdi-truck:before {
  content: "\F53D";
}

.mdi-truck-delivery:before {
  content: "\F53E";
}

.mdi-truck-fast:before {
  content: "\F787";
}

.mdi-truck-trailer:before {
  content: "\F726";
}

.mdi-tshirt-crew:before {
  content: "\F53F";
}

.mdi-tshirt-v:before {
  content: "\F540";
}

.mdi-tumblr:before {
  content: "\F541";
}

.mdi-tumblr-reblog:before {
  content: "\F542";
}

.mdi-tune:before {
  content: "\F62E";
}

.mdi-tune-vertical:before {
  content: "\F66A";
}

.mdi-twitch:before {
  content: "\F543";
}

.mdi-twitter:before {
  content: "\F544";
}

.mdi-twitter-box:before {
  content: "\F545";
}

.mdi-twitter-circle:before {
  content: "\F546";
}

.mdi-twitter-retweet:before {
  content: "\F547";
}

.mdi-uber:before {
  content: "\F748";
}

.mdi-ubuntu:before {
  content: "\F548";
}

.mdi-ultra-high-definition:before {
  content: "\F7F8";
}

.mdi-umbraco:before {
  content: "\F549";
}

.mdi-umbrella:before {
  content: "\F54A";
}

.mdi-umbrella-outline:before {
  content: "\F54B";
}

.mdi-undo:before {
  content: "\F54C";
}

.mdi-undo-variant:before {
  content: "\F54D";
}

.mdi-unfold-less-horizontal:before {
  content: "\F54E";
}

.mdi-unfold-less-vertical:before {
  content: "\F75F";
}

.mdi-unfold-more-horizontal:before {
  content: "\F54F";
}

.mdi-unfold-more-vertical:before {
  content: "\F760";
}

.mdi-ungroup:before {
  content: "\F550";
}

.mdi-unity:before {
  content: "\F6AE";
}

.mdi-untappd:before {
  content: "\F551";
}

.mdi-update:before {
  content: "\F6AF";
}

.mdi-upload:before {
  content: "\F552";
}

.mdi-upload-network:before {
  content: "\F6F5";
}

.mdi-usb:before {
  content: "\F553";
}

.mdi-van-passenger:before {
  content: "\F7F9";
}

.mdi-van-utility:before {
  content: "\F7FA";
}

.mdi-vanish:before {
  content: "\F7FB";
}

.mdi-vector-arrange-above:before {
  content: "\F554";
}

.mdi-vector-arrange-below:before {
  content: "\F555";
}

.mdi-vector-circle:before {
  content: "\F556";
}

.mdi-vector-circle-variant:before {
  content: "\F557";
}

.mdi-vector-combine:before {
  content: "\F558";
}

.mdi-vector-curve:before {
  content: "\F559";
}

.mdi-vector-difference:before {
  content: "\F55A";
}

.mdi-vector-difference-ab:before {
  content: "\F55B";
}

.mdi-vector-difference-ba:before {
  content: "\F55C";
}

.mdi-vector-intersection:before {
  content: "\F55D";
}

.mdi-vector-line:before {
  content: "\F55E";
}

.mdi-vector-point:before {
  content: "\F55F";
}

.mdi-vector-polygon:before {
  content: "\F560";
}

.mdi-vector-polyline:before {
  content: "\F561";
}

.mdi-vector-radius:before {
  content: "\F749";
}

.mdi-vector-rectangle:before {
  content: "\F5C6";
}

.mdi-vector-selection:before {
  content: "\F562";
}

.mdi-vector-square:before {
  content: "\F001";
}

.mdi-vector-triangle:before {
  content: "\F563";
}

.mdi-vector-union:before {
  content: "\F564";
}

.mdi-verified:before {
  content: "\F565";
}

.mdi-vibrate:before {
  content: "\F566";
}

.mdi-video:before {
  content: "\F567";
}

.mdi-video-3d:before {
  content: "\F7FC";
}

.mdi-video-off:before {
  content: "\F568";
}

.mdi-video-switch:before {
  content: "\F569";
}

.mdi-view-agenda:before {
  content: "\F56A";
}

.mdi-view-array:before {
  content: "\F56B";
}

.mdi-view-carousel:before {
  content: "\F56C";
}

.mdi-view-column:before {
  content: "\F56D";
}

.mdi-view-dashboard:before {
  content: "\F56E";
}

.mdi-view-day:before {
  content: "\F56F";
}

.mdi-view-grid:before {
  content: "\F570";
}

.mdi-view-headline:before {
  content: "\F571";
}

.mdi-view-list:before {
  content: "\F572";
}

.mdi-view-module:before {
  content: "\F573";
}

.mdi-view-parallel:before {
  content: "\F727";
}

.mdi-view-quilt:before {
  content: "\F574";
}

.mdi-view-sequential:before {
  content: "\F728";
}

.mdi-view-stream:before {
  content: "\F575";
}

.mdi-view-week:before {
  content: "\F576";
}

.mdi-vimeo:before {
  content: "\F577";
}

.mdi-vine:before {
  content: "\F578";
}

.mdi-violin:before {
  content: "\F60F";
}

.mdi-visualstudio:before {
  content: "\F610";
}

.mdi-vk:before {
  content: "\F579";
}

.mdi-vk-box:before {
  content: "\F57A";
}

.mdi-vk-circle:before {
  content: "\F57B";
}

.mdi-vlc:before {
  content: "\F57C";
}

.mdi-voice:before {
  content: "\F5CB";
}

.mdi-voicemail:before {
  content: "\F57D";
}

.mdi-volume-high:before {
  content: "\F57E";
}

.mdi-volume-low:before {
  content: "\F57F";
}

.mdi-volume-medium:before {
  content: "\F580";
}

.mdi-volume-minus:before {
  content: "\F75D";
}

.mdi-volume-mute:before {
  content: "\F75E";
}

.mdi-volume-off:before {
  content: "\F581";
}

.mdi-volume-plus:before {
  content: "\F75C";
}

.mdi-vpn:before {
  content: "\F582";
}

.mdi-walk:before {
  content: "\F583";
}

.mdi-wall:before {
  content: "\F7FD";
}

.mdi-wallet:before {
  content: "\F584";
}

.mdi-wallet-giftcard:before {
  content: "\F585";
}

.mdi-wallet-membership:before {
  content: "\F586";
}

.mdi-wallet-travel:before {
  content: "\F587";
}

.mdi-wan:before {
  content: "\F588";
}

.mdi-washing-machine:before {
  content: "\F729";
}

.mdi-watch:before {
  content: "\F589";
}

.mdi-watch-export:before {
  content: "\F58A";
}

.mdi-watch-import:before {
  content: "\F58B";
}

.mdi-watch-vibrate:before {
  content: "\F6B0";
}

.mdi-water:before {
  content: "\F58C";
}

.mdi-water-off:before {
  content: "\F58D";
}

.mdi-water-percent:before {
  content: "\F58E";
}

.mdi-water-pump:before {
  content: "\F58F";
}

.mdi-watermark:before {
  content: "\F612";
}

.mdi-waves:before {
  content: "\F78C";
}

.mdi-weather-cloudy:before {
  content: "\F590";
}

.mdi-weather-fog:before {
  content: "\F591";
}

.mdi-weather-hail:before {
  content: "\F592";
}

.mdi-weather-lightning:before {
  content: "\F593";
}

.mdi-weather-lightning-rainy:before {
  content: "\F67D";
}

.mdi-weather-night:before {
  content: "\F594";
}

.mdi-weather-partlycloudy:before {
  content: "\F595";
}

.mdi-weather-pouring:before {
  content: "\F596";
}

.mdi-weather-rainy:before {
  content: "\F597";
}

.mdi-weather-snowy:before {
  content: "\F598";
}

.mdi-weather-snowy-rainy:before {
  content: "\F67E";
}

.mdi-weather-sunny:before {
  content: "\F599";
}

.mdi-weather-sunset:before {
  content: "\F59A";
}

.mdi-weather-sunset-down:before {
  content: "\F59B";
}

.mdi-weather-sunset-up:before {
  content: "\F59C";
}

.mdi-weather-windy:before {
  content: "\F59D";
}

.mdi-weather-windy-variant:before {
  content: "\F59E";
}

.mdi-web:before {
  content: "\F59F";
}

.mdi-webcam:before {
  content: "\F5A0";
}

.mdi-webhook:before {
  content: "\F62F";
}

.mdi-webpack:before {
  content: "\F72A";
}

.mdi-wechat:before {
  content: "\F611";
}

.mdi-weight:before {
  content: "\F5A1";
}

.mdi-weight-kilogram:before {
  content: "\F5A2";
}

.mdi-whatsapp:before {
  content: "\F5A3";
}

.mdi-wheelchair-accessibility:before {
  content: "\F5A4";
}

.mdi-white-balance-auto:before {
  content: "\F5A5";
}

.mdi-white-balance-incandescent:before {
  content: "\F5A6";
}

.mdi-white-balance-iridescent:before {
  content: "\F5A7";
}

.mdi-white-balance-sunny:before {
  content: "\F5A8";
}

.mdi-widgets:before {
  content: "\F72B";
}

.mdi-wifi:before {
  content: "\F5A9";
}

.mdi-wifi-off:before {
  content: "\F5AA";
}

.mdi-wii:before {
  content: "\F5AB";
}

.mdi-wiiu:before {
  content: "\F72C";
}

.mdi-wikipedia:before {
  content: "\F5AC";
}

.mdi-window-close:before {
  content: "\F5AD";
}

.mdi-window-closed:before {
  content: "\F5AE";
}

.mdi-window-maximize:before {
  content: "\F5AF";
}

.mdi-window-minimize:before {
  content: "\F5B0";
}

.mdi-window-open:before {
  content: "\F5B1";
}

.mdi-window-restore:before {
  content: "\F5B2";
}

.mdi-windows:before {
  content: "\F5B3";
}

.mdi-wordpress:before {
  content: "\F5B4";
}

.mdi-worker:before {
  content: "\F5B5";
}

.mdi-wrap:before {
  content: "\F5B6";
}

.mdi-wrench:before {
  content: "\F5B7";
}

.mdi-wunderlist:before {
  content: "\F5B8";
}

.mdi-xaml:before {
  content: "\F673";
}

.mdi-xbox:before {
  content: "\F5B9";
}

.mdi-xbox-controller:before {
  content: "\F5BA";
}

.mdi-xbox-controller-battery-alert:before {
  content: "\F74A";
}

.mdi-xbox-controller-battery-empty:before {
  content: "\F74B";
}

.mdi-xbox-controller-battery-full:before {
  content: "\F74C";
}

.mdi-xbox-controller-battery-low:before {
  content: "\F74D";
}

.mdi-xbox-controller-battery-medium:before {
  content: "\F74E";
}

.mdi-xbox-controller-battery-unknown:before {
  content: "\F74F";
}

.mdi-xbox-controller-off:before {
  content: "\F5BB";
}

.mdi-xda:before {
  content: "\F5BC";
}

.mdi-xing:before {
  content: "\F5BD";
}

.mdi-xing-box:before {
  content: "\F5BE";
}

.mdi-xing-circle:before {
  content: "\F5BF";
}

.mdi-xml:before {
  content: "\F5C0";
}

.mdi-xmpp:before {
  content: "\F7FE";
}

.mdi-yammer:before {
  content: "\F788";
}

.mdi-yeast:before {
  content: "\F5C1";
}

.mdi-yelp:before {
  content: "\F5C2";
}

.mdi-yin-yang:before {
  content: "\F67F";
}

.mdi-youtube-play:before {
  content: "\F5C3";
}

.mdi-zip-box:before {
  content: "\F5C4";
}

.mdi-blank:before {
  content: "\f68c";
  visibility: hidden;
}

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear;
}

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
html {
  background: #f7f9f9 !important;
}

body {
  font-family: "Roboto", sans-serif !important;
  background: #f7f9f9 !important;
  font-size: 16px;
  color: #838383;
  font-weight: 400;
  height: 100vh;
}

label {
  font-size: 16px !important;
  color: #838383 !important;
  font-weight: 500 !important;
}

.text-gray {
  color: #838383 !important;
}

.text-18 {
  font-size: 18px !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-14 {
  font-size: 14px !important;
}

.text-12 {
  font-size: 12px !important;
}

.text-10 {
  font-size: 10px !important;
}

.text-color-blue {
  color: #015488 !important;
}

.vh-90 {
  height: calc(100vh - 100px) !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.form-control.is-invalid {
  border: 1px solid #F80303 !important;
}

.form-control, .table, table, td, th, p, span, li, select, option, div, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, label, button {
  color: #838383;
}

.form-control {
  border: 1px solid #D5D5D5;
}
.form-control.is-invalid, .form-control .was-validated, .form-control .invalid {
  background-image: none !important;
  padding-right: 0px !important;
}

.page-header {
  font-size: 18px;
  color: #C28404;
  font-weight: 400;
  line-height: normal !important;
}

@media (max-width: 576px) {
  .container {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.btn-150 {
  width: 150px !important;
}

.login-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-color: #212121;
}

.app-bg {
  background: url("/assets/images/login-bg-1.jpg") no-repeat;
  opacity: 0.2;
  background-size: cover;
}

.custom-alert {
  position: fixed !important;
  z-index: 9999;
  top: 75px !important;
  right: 10px !important;
  width: 300px;
}
.custom-alert mat-icon {
  width: 16px !important;
  height: 16px !important;
  font-size: 16px !important;
}

.checkbox label {
  display: inline-block;
  padding-left: 5px;
  position: relative;
  font-weight: normal;
}
.checkbox label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid gray;
  content: "";
  display: inline-block;
  height: 17px;
  left: 0;
  margin-left: -20px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none !important;
  margin-top: 2px;
}
.checkbox label::after {
  color: #333333;
  display: inline-block;
  font-size: 16px;
  height: 16px;
  left: -1px;
  margin-left: -20px;
  padding-left: 2px;
  padding-top: 1px;
  position: absolute;
  top: -1px;
  width: 16px;
}
.checkbox input[type=checkbox] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.checkbox input[type=checkbox]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type=checkbox]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}
.checkbox input[type=checkbox]:checked + label::after {
  content: "\f12c";
  font-family: "Material Design Icons";
  font-weight: bold;
}
.checkbox input[type=checkbox]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.box-shadow {
  -webkit-box-shadow: 3px 3px 5px 6px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 3px 3px 5px 6px #ccc; /* Firefox 3.5 - 3.6 */
  box-shadow: 3px 3px 5px 6px #ccc;
}

.unauthorized-container mat-icon {
  width: 80px !important;
  height: 80px !important;
  font-size: 80px !important;
  color: lightcoral;
}
.unauthorized-container h1, .unauthorized-container .h1 {
  font-size: 48px;
}

.content .content-body {
  margin-bottom: 0px;
  min-height: calc(100vh - 70px);
  padding-bottom: 100px;
  position: relative;
  margin: 20px;
}
@media (max-width: 576px) {
  .content .content-body {
    margin: 10px;
  }
}

.header {
  height: 67px;
  width: 100% !important;
  color: #ffffff !important;
  background: #212121 !important;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}
.header .navbar-nav {
  flex-direction: row !important;
}
.header .navbar-nav li {
  display: flex;
  align-items: center;
}
.header .navbar-nav li::after {
  content: "|";
  margin: 0 10px;
}
.header .navbar-nav li:last-child::after {
  content: "";
  margin: 0 10px;
}
.header .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.6) !important;
}
.header .navbar-nav .nav-link:hover {
  color: #ffffff !important;
}
.header .nav-link.active {
  color: #ffffff !important;
  font-weight: 500;
}
.header a {
  color: #ffffff !important;
  text-decoration: none !important;
}
@media (max-width: 767px) {
  .header .menu-nav {
    display: none;
  }
}
.header .context-menu {
  display: inherit;
}
@media (min-width: 768px) {
  .header .context-menu {
    display: none !important;
  }
}
.header .context-menu .context-menu-btn {
  text-align: right;
  width: 20px;
  line-height: normal;
  height: auto;
  padding: 0px;
  border: none;
  box-shadow: none;
  color: #838383;
}
.header .context-menu .context-menu-content {
  box-shadow: none;
  padding: 10px 15px 5px 15px !important;
  border-radius: 4px;
  border: 1px solid #D5D5D5;
}
.header .context-menu .context-menu-content a {
  color: #838383 !important;
  font-size: 16px;
  margin: 5px 0;
}
.header .context-menu .context-menu-content a:hover {
  font-weight: 500 !important;
  text-decoration: none !important;
}
.header .context-menu .context-menu-content a.active {
  font-weight: 500 !important;
}
.header .context-menu .context-menu-content .context-menu-icon {
  width: 22px;
  text-align: right;
  margin-right: 10px;
  display: inline-block;
}
.header .context-menu .context-menu-content .context-menu-icon-w {
  width: 20px;
}
.header .context-menu .context-menu-content .context-menu-item {
  margin-bottom: 5px;
}
.header .context-menu .context-menu-content .context-menu-sub-title {
  font-size: 16px;
  font-weight: 400;
  margin-left: 31px;
  margin-bottom: 5px;
}
.header .student-context-menu {
  display: inherit;
}
.header .student-context-menu .context-menu-btn {
  text-align: right;
  width: 20px;
  line-height: normal;
  height: auto;
  padding: 0px;
  border: none;
  box-shadow: none;
  color: #838383;
}
.header .student-context-menu .context-menu-content {
  box-shadow: none;
  padding: 10px 0px 5px 0px !important;
  border-radius: 4px;
  border: 1px solid #D5D5D5;
}
.header .student-context-menu .context-menu-content a {
  color: #838383 !important;
  font-size: 16px;
  margin: 5px 0;
}
.header .student-context-menu .context-menu-content a:hover {
  font-weight: 500 !important;
  text-decoration: none !important;
}
.header .student-context-menu .context-menu-content a.active {
  font-weight: 500 !important;
}
.header .student-context-menu .context-menu-content .context-menu-icon {
  width: 22px;
  text-align: right;
  margin-right: 10px;
  display: inline-block;
}
.header .student-context-menu .context-menu-content .context-menu-icon-w {
  width: 20px;
}
.header .student-context-menu .context-menu-content .context-menu-item {
  margin-bottom: 5px;
  padding: 0px 10px !important;
  cursor: pointer;
}
.header .student-context-menu .context-menu-content .context-menu-sub-title {
  font-size: 16px;
  font-weight: 400;
  margin-left: 31px;
  margin-bottom: 5px;
}

.breadcrumb {
  margin-bottom: 0px !important;
}
.breadcrumb .breadcrumb-item, .breadcrumb .breadcrumb-item.active {
  font-style: italic !important;
}
.breadcrumb .breadcrumb-item:focus, .breadcrumb .breadcrumb-item.active:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.breadcrumb .breadcrumb-item:hover, .breadcrumb .breadcrumb-item.active:hover {
  color: darkgray !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.border-0 {
  border: none !important;
}

.hover-pointer {
  cursor: pointer !important;
}

.no-hover {
  cursor: default !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-box {
  border: 1px solid #D5D5D5;
  border-radius: 4px;
  padding-right: 50px;
  position: relative;
  background: #ffffff !important;
}
.search-box .search-icon {
  position: absolute;
  right: 9px;
  top: 9px;
}
.search-box .search-icon:before {
  font-size: 18px;
  color: #D5D5D5;
}

@media (max-width: 575px) {
  .mcq-btn {
    margin-top: 20px;
    text-align: right;
  }
}

.footer {
  border: none;
  font-size: 12px;
  color: #838383;
  font-weight: 400;
  text-align: center;
  left: 0;
  padding: 20px 15px;
  background: #f7f9f9 !important;
}

.breadcrumb {
  background: none !important;
  padding: 0 1rem;
  margin-bottom: 4px;
}

.user_card {
  height: 400px;
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  background: #212121;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.brand_logo_container {
  text-align: center;
}

.login-form-container {
  margin: 50px 25px 0px 25px;
}

.invalid-login-form {
  margin-top: 80px;
}

.login_btn {
  width: 100%;
  background-image: linear-gradient(#F4BA03, #C28404);
  color: white !important;
}

.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.login_container {
  padding: 0 2rem;
}

.user_card .input-group-text {
  background: #808080 !important;
  color: white !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}

.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.dropshadow-box, .profile-box, .custom-accordion, .profile-details {
  box-shadow: none !important;
  border: 1px solid #D5D5D5;
  background: #ffffff !important;
  border-radius: 4px;
  font-size: 17px !important;
}
.dropshadow-box mat-icon, .profile-box mat-icon, .custom-accordion mat-icon, .profile-details mat-icon {
  margin-right: 10px !important;
}
@media (max-width: 576px) {
  .dropshadow-box mat-icon, .profile-box mat-icon, .custom-accordion mat-icon, .profile-details mat-icon {
    line-height: 1.5 !important;
    font-size: 18px !important;
  }
}
@media (max-width: 576px) {
  .dropshadow-box .bd-highlight mat-icon, .profile-box .bd-highlight mat-icon, .custom-accordion .bd-highlight mat-icon, .profile-details .bd-highlight mat-icon {
    margin-right: 0px !important;
  }
}
@media (max-width: 576px) {
  .dropshadow-box, .profile-box, .custom-accordion, .profile-details {
    font-size: 14px !important;
  }
}

.date-picker .input-group-text {
  height: calc(1.5em + 0.75rem + 2px) !important;
  border-radius: 0 0.25rem 0.25rem 0 !important;
  padding: 0px 15px !important;
  border-left: none !important;
  cursor: pointer;
}

.list-table mat-icon {
  color: #bdbaba;
  cursor: pointer;
  font-size: 20px;
}
.list-table mat-icon:hover:hover {
  color: #7d7b7b !important;
}

.btn-block + .btn-block {
  margin-top: 0px !important;
}

.dropdown-item.active span {
  color: #ffffff !important;
  font-weight: lighter !important;
}

.detail-table tbody th {
  padding: 0.25rem !important;
  border: none !important;
  width: 150px !important;
}
.detail-table tbody td {
  padding: 0.25rem !important;
  border: none !important;
}

.multiselect-dropdown .selected-item > span {
  color: #ffffff !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffffff;
  border: 1px solid #137FCE;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background: #137FCE;
  border: 1px solid #137FCE;
}

.correct-option {
  font-weight: 700 !important;
}
.correct-option h1, .correct-option .h1, .correct-option h2, .correct-option .h2, .correct-option h3, .correct-option .h3, .correct-option h4, .correct-option .h4, .correct-option h5, .correct-option .h5, .correct-option h4 {
  font-weight: 700 !important;
}

.img-preview {
  width: 250px !important;
  height: 250px !important;
}
.img-preview img {
  width: 100% !important;
}

.mcq-correct-answer .form-control, .mcq-correct-answer .table, .mcq-correct-answer table, .mcq-correct-answer td, .mcq-correct-answer th, .mcq-correct-answer p, .mcq-correct-answer span, .mcq-correct-answer li, .mcq-correct-answer select, .mcq-correct-answer option, .mcq-correct-answer div, .mcq-correct-answer h1, .mcq-correct-answer .h1, .mcq-correct-answer h2, .mcq-correct-answer .h2, .mcq-correct-answer h3, .mcq-correct-answer .h3, .mcq-correct-answer h4, .mcq-correct-answer .h4, .mcq-correct-answer h5, .mcq-correct-answer .h5, .mcq-correct-answer h6, .mcq-correct-answer .h6, .mcq-correct-answer label, .mcq-correct-answer button {
  color: #28a745 !important;
}
.mcq-correct-answer p:last-child {
  margin-bottom: 0px !important;
}

.test-details .list-group-item.active {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
.test-details .list-group-item.wrong {
  background-color: #AB4441 !important;
  border-color: #AB4441 !important;
}
.test-details .mcq-correct-answer .form-control, .test-details .mcq-correct-answer .table, .test-details .mcq-correct-answer table, .test-details .mcq-correct-answer td, .test-details .mcq-correct-answer th, .test-details .mcq-correct-answer p, .test-details .mcq-correct-answer span, .test-details .mcq-correct-answer li, .test-details .mcq-correct-answer select, .test-details .mcq-correct-answer option, .test-details .mcq-correct-answer div, .test-details .mcq-correct-answer h1, .test-details .mcq-correct-answer .h1, .test-details .mcq-correct-answer h2, .test-details .mcq-correct-answer .h2, .test-details .mcq-correct-answer h3, .test-details .mcq-correct-answer .h3, .test-details .mcq-correct-answer h4, .test-details .mcq-correct-answer .h4, .test-details .mcq-correct-answer h5, .test-details .mcq-correct-answer .h5, .test-details .mcq-correct-answer h6, .test-details .mcq-correct-answer .h6, .test-details .mcq-correct-answer label, .test-details .mcq-correct-answer button {
  color: #ffffff !important;
}
.test-details .mcq-wrong-answer .form-control, .test-details .mcq-wrong-answer .table, .test-details .mcq-wrong-answer table, .test-details .mcq-wrong-answer td, .test-details .mcq-wrong-answer th, .test-details .mcq-wrong-answer p, .test-details .mcq-wrong-answer span, .test-details .mcq-wrong-answer li, .test-details .mcq-wrong-answer select, .test-details .mcq-wrong-answer option, .test-details .mcq-wrong-answer div, .test-details .mcq-wrong-answer h1, .test-details .mcq-wrong-answer .h1, .test-details .mcq-wrong-answer h2, .test-details .mcq-wrong-answer .h2, .test-details .mcq-wrong-answer h3, .test-details .mcq-wrong-answer .h3, .test-details .mcq-wrong-answer h4, .test-details .mcq-wrong-answer .h4, .test-details .mcq-wrong-answer h5, .test-details .mcq-wrong-answer .h5, .test-details .mcq-wrong-answer h6, .test-details .mcq-wrong-answer .h6, .test-details .mcq-wrong-answer label, .test-details .mcq-wrong-answer button {
  color: #ffffff !important;
}
.test-details .no-margins p:last-child {
  margin-bottom: 0px !important;
}

.signup-card {
  width: 350px !important;
  box-shadow: 0px 0px 74px 2px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 0px 0px 74px 2px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 0px 0px 74px 2px rgba(0, 0, 0, 0.41);
}

.badge-outline {
  background: none;
  border: 1px solid #d5d5d5;
  font-size: 14px !important;
  text-transform: uppercase;
  min-width: 59px;
  padding: 1px 10px;
  font-weight: 600;
}

.badge-outline-danger {
  border: 2px solid #EC407A;
  color: #EC407A;
}

.badge-outline-success {
  border: 2px solid #139245;
  color: #139245;
}

.student-test-series {
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 12px;
}
.student-test-series .card-body {
  padding: 10px !important;
}
.student-test-series .test-title {
  border-bottom: 1px solid #d3d3d3;
}
.student-test-series .test-title .card-title {
  color: #C28404 !important;
}
@media (max-width: 576px) {
  .student-test-series .test-title .card-title {
    font-size: 16px !important;
  }
}
.student-test-series .test-title span.badge {
  padding: 5px;
}
@media (max-width: 576px) {
  .student-test-series .test-title span.badge {
    font-size: 12px !important;
  }
}
.student-test-series .test-body {
  border-bottom: 1px solid #d3d3d3;
}
@media (max-width: 576px) {
  .student-test-series .test-body .card-text {
    font-size: 14px !important;
  }
}
.student-test-series .test-body .card-text span {
  color: #C28404 !important;
}
.student-test-series .test-body .test-details div {
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  .student-test-series.note-series .test-title {
    align-items: center;
  }
}
@media (max-width: 576px) {
  .student-test-series.note-series .test-title .card-title {
    font-size: 18px !important;
    margin: 0px !important;
  }
}
.student-test-series.note-series .test-title label {
  font-size: 14px !important;
}
.student-test-series.note-series .test-title label span {
  color: #053bff !important;
}
@media (max-width: 576px) {
  .student-test-series.note-series .test-title span.badge {
    font-size: 12px !important;
  }
}

@media (max-width: 576px) {
  .student-test-series.account-notes .test-title {
    align-items: center;
  }
}
@media (max-width: 576px) {
  .student-test-series.account-notes .test-title .card-title {
    margin: 0px !important;
  }
}
.student-test-series.account-notes .test-title label {
  font-size: 12px !important;
}
.student-test-series.account-notes .test-title label span {
  color: #053bff !important;
}
@media (max-width: 576px) {
  .student-test-series.account-notes .test-title span.badge {
    font-size: 12px !important;
  }
}

.form-group.hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.custom-day.focused {
  background-color: #e6e6e6;
}

.custom-day.range, .custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}

.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}

@media (min-width: 576px) {
  .student-header {
    justify-content: space-between !important;
  }
}

.buy-now button {
  position: fixed;
  bottom: 45px;
  right: 10px;
  width: 150px !important;
  z-index: 999;
}

.cart_button {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.cart_preview {
  display: table;
  margin: 0 auto;
}

.test-btn {
  background: linear-gradient(#e5b248, #C28404);
  border: none;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Century Gothic" !important;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, span {
  font-family: "Century Gothic" !important;
}

@font-face {
  font-family: "Century Gothic";
  src: url("./assets/font/Century-Gothic.ttf");
}
@font-face {
  font-family: "Century Gothic Bold";
  src: url("./assets/font/Century-Gothic-Bold.TTF");
}
.cke_toolgroup {
  background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)) !important;
  border-color: rgb(0, 0, 0) !important;
}

.cke_combo_button {
  background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)) !important;
  border-color: rgb(0, 0, 0) !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (max-width: 576px) {
  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.printScreen::after {
  content: "";
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
}