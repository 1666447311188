@import './variables';

html {
    @include background($body-bg);
  }
  body {
    font-family: "Roboto", sans-serif !important;
    @include background($body-bg);
    @include text-display($font-16, $gray-body, $font-weight-normal);
    height: 100vh;
  }

  label {
    font-size: 16px !important;
    color: $gray-body !important;
    font-weight: 500 !important;
}

.text-gray {
  color: $gray-body !important;
}

.text-18{
  font-size: $font-18 !important;
}

.text-16{
  font-size: $font-16 !important;
}

.text-14 {
  font-size: $font-14 !important;
}

.text-12 {
  font-size: $font-12 !important;
}

.text-10 {
  font-size: $font-10 !important;
}

.text-color-blue{
  color: $d-blue !important;
}

.vh-90{
  height: calc(100vh - 100px) !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.form-control.is-invalid {
  border: 1px solid #F80303 !important;
}

.form-control, .table, table, td, th, p, span, li, select, option, div, h1, h2, h3, h4, h5, h6, label, button {
  color: $gray-body;
}

.form-control {
  border:1px solid $gray-D5;
  &.is-invalid, .was-validated, .invalid {
  background-image: none !important;
  padding-right: 0px !important;
  }
}

.page-header {
  font-size: 18px;
  color: #C28404;
  font-weight: 400;
  line-height: normal !important;
}

  .container {
    @media (max-width: $min-sm) {
      padding-right: 0 !important;
      padding-left: 0 !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }

  .btn-150 {
    width: 150px !important;
  }

  .login-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-color: #212121;
}

.app-bg {
    background: url($login-bg) no-repeat;
    opacity: .2;
    background-size: cover;
}

.custom-alert {
  position: fixed !important;
  z-index: 9999;
  top: 75px !important;
  right: 10px !important;
  width: 300px;
  mat-icon{
    width: 16px !important;
    height: 16px !important ;
    font-size: 16px !important;
  }
}

.checkbox {
  //padding-left: 20px;
  label {
    display: inline-block;
    padding-left: 5px;
    position: relative;
    font-weight: normal;

    &::before {
      -o-transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      background-color: $white;
      border-radius: 5px;
      border: 1px solid gray;
      content: "";
      display: inline-block;
      height: 17px;
      left: 0;
      margin-left: -20px;
      position: absolute;
      transition: 0.3s ease-in-out;
      width: 17px;
      outline: none !important;
      margin-top: 2px;
    }
    &::after {
      color: $lightdark3;
      display: inline-block;
      font-size: 16px;
      height: 16px;
      left: -1px;
      margin-left: -20px;
      padding-left: 2px;
      padding-top: 1px;
      position: absolute;
      top: -1px;
      width: 16px;
    }
  }
  input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important;

    &:disabled + label {
      opacity: 0.65;
    }
  }
  input[type="checkbox"]:focus + label {
    &::before {
      outline-offset: -2px;
      outline: none;
    }
  }
  input[type="checkbox"]:checked + label {
    &::after {
      content: "\F12C";
      font-family: "Material Design Icons";
      font-weight: bold;
    }
  }
  input[type="checkbox"]:disabled + label {
    &::before {
      background-color: $light;
      cursor: not-allowed;
    }
  }
}

.box-shadow {
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 6px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 6px #ccc; 
}

.unauthorized-container{
  mat-icon{
      width: 80px !important;
      height: 80px !important ;
      font-size: 80px !important;
      color: lightcoral;
  }
  h1{
      font-size: 48px;
  }
}

.content {
  .content-body {
    margin-bottom: 0px;
    min-height: calc(100vh - 70px);
    padding-bottom: 100px;
    position: relative;
    margin: 20px;
    @media (max-width: $min-sm) {
      margin: 10px; 
    }
  }
}
  
  .header {
    height: 67px;
    width: 100% !important;
    color: #ffffff !important;
    background: #212121 !important;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    .navbar-nav
    {
        flex-direction: row !important;
        li {
          display: flex;
          align-items: center;
          &::after {
            content: "|";
            margin: 0 10px;
          }
          &:last-child::after {
            content: '';
            margin: 0 10px;
          }
        }
        .nav-link {
          color: rgba(255, 255, 255, 0.6) !important;
            
            &:hover{
                color: #ffffff !important;
            }
        } 
    }
    .nav-link.active{
        color: #ffffff !important;
        font-weight: 500;
    }
    a {
        color: #ffffff !important;
        text-decoration: none !important;
    }

    .menu-nav {
      @media (max-width: $md) {
        display: none;
      }
    }

    .context-menu{
      @media (min-width: $min-md) {
        display: none !important;
      }
      display: inherit;
      .context-menu-btn{
        text-align: right;
        width: 20px;
        line-height: normal;
        height: auto;
        padding: 0px;
        border: none;
        box-shadow: none;
        color:$gray-body;
      }
      .context-menu-content{
        box-shadow: none;
        padding: 10px 15px 5px 15px !important;
        border-radius: 4px;
        border:1px solid $gray-D5;
        a{
          color:$gray-body !important;
          font-size: $font-16;
          margin:5px 0;
          &:hover {
            font-weight: 500 !important;  
            text-decoration: none !important;
          }
        }
        a.active {
          font-weight: 500 !important;
        }
        .context-menu-icon{
          width:22px;
          text-align: right;
          margin-right:10px;
          display: inline-block;
        }
        .context-menu-icon-w{
          width: 20px;
        }
        .context-menu-item{
          margin-bottom: 5px;
        }
        .context-menu-sub-title{
          font-size: $font-16;
          font-weight: 400;
          margin-left:31px;
          margin-bottom: 5px;
        }
    }
    }
    .student-context-menu{
      // @media (min-width: $min-md) {
      //   display: none !important;
      // }
      display: inherit;
      .context-menu-btn{
        text-align: right;
        width: 20px;
        line-height: normal;
        height: auto;
        padding: 0px;
        border: none;
        box-shadow: none;
        color:$gray-body;
      }
      .context-menu-content{
        box-shadow: none;
        padding: 10px 0px 5px 0px !important;
        border-radius: 4px;
        border:1px solid $gray-D5;
        a{
          color:$gray-body !important;
          font-size: $font-16;
          margin:5px 0;
          &:hover {
            font-weight: 500 !important;  
            text-decoration: none !important;
          }
        }
        a.active {
          font-weight: 500 !important;
        }
        .context-menu-icon{
          width:22px;
          text-align: right;
          margin-right:10px;
          display: inline-block;
        }
        .context-menu-icon-w{
          width: 20px;
        }
        .context-menu-item{
          margin-bottom: 5px;
          padding: 0px 10px !important;
          cursor: pointer;
        }
        .context-menu-sub-title{
          font-size: $font-16;
          font-weight: 400;
          margin-left:31px;
          margin-bottom: 5px;
        }
    }
    }
}
.breadcrumb {
  margin-bottom: 0px !important;
  .breadcrumb-item, .breadcrumb-item.active{
    font-style: italic !important;
    &:focus{
      outline: none;
      border: none;
      box-shadow: none;
    }
    &:hover {
      color: darkgray !important;
    }
  }
}

.rounded-circle {
    border-radius: 50% !important;
}

.border-0 {
  border: none !important;
}

.hover-pointer {
  cursor: pointer !important;
}

.no-hover{
  cursor:default !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-box{
  border:1px solid $gray-D5;
  border-radius:4px;
  padding-right:50px;
  position: relative;
  @include background($white);
  .search-icon{
      position: absolute;
      right:9px;
      top:9px;
      &:before{
          font-size: $font-18;
          color:$gray-D5;
      }
  }
}

.mcq-btn {
  @media (max-width: $sm) {
    margin-top: 20px;
    text-align: right;
  }
}

.footer {
  border: none;
  @include text-display($font-12, $gray-body, $font-weight-normal);
  text-align: center;
  left: 0;
  padding: 20px 15px;
  @include background($body-bg);
}

.breadcrumb {
  background: none !important;
  padding: 0 1rem;
  margin-bottom: 4px;
}

//Login in styles
.user_card {
  height: 400px;
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  background: #212121;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;

}
.brand_logo_container {
  text-align: center;
}
// .brand_logo {
//   height: 150px;
//   width: 150px;
//   border-radius: 50%;
//   border: 2px solid white;
// }
.login-form-container {
  margin: 50px 25px 0px 25px;
}
.invalid-login-form {
  margin-top: 80px;
}
.login_btn {
  width: 100%;
  background-image: linear-gradient(#F4BA03, #C28404);
  color: white !important;
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
.user_card {
  .input-group-text {
    background: $gray-main !important;
    color: white !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
  }
  
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
// .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
//   background-color: lightgray !important;
// }

.dropshadow-box, .profile-box, .custom-accordion, .profile-details {
  box-shadow: none !important;
  border: 1px solid #D5D5D5;
  background: #ffffff !important;
  border-radius: 4px;
  font-size: 17px !important;
  mat-icon {
    @media (max-width: $min-sm) {
      line-height: 1.5 !important;
      font-size: 18px !important;
    }
    margin-right: 10px !important;
  }
  .bd-highlight{
    mat-icon {
      @media (max-width: $min-sm) {
        margin-right: 0px !important;  
      }
    }
  }
  @media (max-width: $min-sm) {
    font-size: 14px !important;
  }
}

.date-picker {
  .input-group-text{
    height: calc(1.5em + 0.75rem + 2px) !important;
    border-radius: 0 0.25rem 0.25rem 0 !important;
    padding: 0px 15px !important;
    border-left: none !important;
    cursor: pointer;
  }
}

.list-table {
  mat-icon {
    color: #bdbaba;
    cursor: pointer;
    font-size: 20px;
    &:hover:hover {
      color: #7d7b7b !important
    }
  }
}

.btn-block + .btn-block {
  margin-top: 0px !important;
}

.dropdown-item.active {
  span {
    color: #ffffff !important;
    font-weight: lighter !important;
  }
}

.detail-table{
  tbody{
    th{
      padding: 0.25rem !important;
      border: none !important;
      width: 150px !important;
    } 
    td {
      padding: 0.25rem !important;
      border: none !important;
    }
  } 
}

.multiselect-dropdown{
  .selected-item > span {
    color: #ffffff !important;
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffffff;
  border: 1px solid #137FCE;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background: #137FCE;
  border: 1px solid #137FCE;
}

.correct-option {
  h1, h2, h3, h4, h5, h4{
    font-weight: 700 !important;  
  }
  font-weight: 700 !important;
}

.img-preview{
  width: 250px !important;
  height: 250px !important;
  img {
      width: 100% !important;
  }
}

.mcq-correct-answer{
  .form-control, .table, table, td, th, p, span, li, select, option, div, h1, h2, h3, h4, h5, h6, label, button{
    color: #28a745 !important;
  }
  p:last-child {
    margin-bottom: 0px !important;
  }
}

.test-details{
  .list-group-item.active{
    background-color: #28a745 !important;
    border-color: #28a745 !important;
  }
  .list-group-item.wrong{
    background-color: $war-text !important;
    border-color: $war-text !important;
  }
  .mcq-correct-answer{
    .form-control, .table, table, td, th, p, span, li, select, option, div, h1, h2, h3, h4, h5, h6, label, button{
      color: #ffffff !important;
    }
  }
  .mcq-wrong-answer{
    .form-control, .table, table, td, th, p, span, li, select, option, div, h1, h2, h3, h4, h5, h6, label, button{
      color: #ffffff !important;
    }
  }
  .no-margins{
    p:last-child {
      margin-bottom: 0px !important;
    }
  }
}

.signup-card{
  width: 350px !important;
  box-shadow: 0px 0px 74px 2px rgba(0,0,0,0.41);
-webkit-box-shadow: 0px 0px 74px 2px rgba(0,0,0,0.41);
-moz-box-shadow: 0px 0px 74px 2px rgba(0,0,0,0.41);
}

.badge-outline {
  background: none;
  border: 1px solid #d5d5d5;
  font-size: 14px !important;
  text-transform: uppercase;
  min-width: 59px;
  padding: 1px 10px;
  font-weight: 600;
}

.badge-outline-danger {
  border: 2px solid #EC407A;;
  color: #EC407A;;
}

.badge-outline-success {
  border: 2px solid #139245;
  color: #139245;
}

.student-test-series{
    border:  1px solid gray;
    border-radius: 5px;
    margin-bottom: 12px;
  .card-body{
      padding: 10px !important;
  }
  .test-title {
      border-bottom: 1px solid #d3d3d3;
      .card-title{
        @media (max-width: $min-sm) {
          font-size: 16px !important;
        }
        color: #C28404 !important;
      }
      span.badge{
        @media (max-width: $min-sm) {
          font-size: 12px !important;
        }
        padding: 5px;
      }
  }
  .test-body {
      border-bottom: 1px solid #d3d3d3;
      .card-text{
        @media (max-width: $min-sm) {
          font-size: 14px !important;
        }
        span{
          color: #C28404 !important;
        }
      }
      .test-details{
        div {
          display: flex;
          align-items: center;
        }
      }
  }
}

.student-test-series.note-series{
  .test-title {
    @media (max-width: $min-sm) {
      align-items: center;
    }
    .card-title{
      @media (max-width: $min-sm) {
        font-size: 18px !important;
        margin: 0px !important
      }
    }
    label {
      font-size: 14px !important;
      span{
        color: #053bff !important;
      }
    }
    span.badge{
      @media (max-width: $min-sm) {
        font-size: 12px !important;
      }
    }
  }
}

.student-test-series.account-notes{
  .test-title {
    @media (max-width: $min-sm) {
      align-items: center;
    }
    .card-title{
      @media (max-width: $min-sm) {
        margin: 0px !important

      }
    }
    label {
      font-size: 12px !important;
      span{
        color: #053bff !important;
      }
    }
    span.badge{
      @media (max-width: $min-sm) {
        font-size: 12px !important;
      }
    }
  }
}

.form-group.hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}
.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.custom-day.focused {
  background-color: #e6e6e6;
}
.custom-day.range, .custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}
.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}

.student-header{
  @media (min-width: $min-sm) {
    justify-content: space-between !important;
  }
}

.buy-now{
  button{
      position: fixed;
      bottom: 45px;
      right: 10px;
      // border-radius: 50% !important;
      width: 150px !important;
      z-index: 999;
  }
}
.cart_button{
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.cart_preview{
  display: table;
  margin: 0 auto;
}

.test-btn{
  background: linear-gradient(#e5b248, #C28404);
    border: none;
}

